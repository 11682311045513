import React from 'react'

const Privacy = ({ openNav, setOpenNav}) => {
  return (
    <div  className={openNav ? "open" : "close"}>
        <div className=' h-full  w-full m-auto  relative group font-yabari  bg-black text-white overflow-hidden  '>
    <div className='  lg:p-[105px] min-[100px]:p-8 lg:pt-32 min-[100px]:mt-32 font-basc'>
     <div className='3xl font-bold'> PRIVACY POLICY FOR NIGERIAN NETBALL FEDERATION (NNF) WEBSITE</div>
<br/> <br/><p className='font-bold'> Effective Date:</p> 25th July, 2023

<br/> <br/>At the Nigerian Netball Federation (NNF), we value the privacy of our website users. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit and interact with our website. By accessing and using our website, you consent to the practices described in this Privacy Policy.

<br/> <br/><p className='font-bold'>1.Information Collection:</p>
<br/> <br/><p className='font-bold'>a.Personal Information:</p> When you voluntarily provide it to us, we may collect personal information such as your name, email address, phone number, and any other information you choose to provide when filling out contact forms or subscribing to newsletters.
<br/> <br/><p className='font-bold'>b.Non-Personal Information:</p> We may also collect non-personal information, including but not limited to your IP address, browser type, operating system, referring website, and browsing behaviour. This information is used to analyse website usage trends and to improve our website's performance and user experience.

<br/> <br/><p className='font-bold'>2.Use of Information:</p>
<br/> <br/><p className='font-bold'>a.Personal Information:</p> We may use your personal information to respond to your inquiries, send you newsletters or other communications, provide updates about the Nigerian Netball Federation, and fulfil any other purposes for which you provided the information.
<br/> <br/><p className='font-bold'>b.Non-Personal Information:</p> Non-personal information is used to understand how visitors interact with our website, identify areas for improvement, and enhance the overall user experience.

<br/> <br/><p className='font-bold'>3.Disclosure of Information:</p>
<br/> <br/><p className='font-bold'>a.Third-Party Service Providers:</p> We may share your personal information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing you, as long as these parties agree to keep the information confidential.
<br/> <br/><p className='font-bold'>b.Legal Requirements:</p> We may disclose your personal information to comply with applicable laws, regulations, legal processes, or governmental requests.

<br/> <br/><p className='font-bold'>4.Cookies and Similar Technologies:</p>
<br/> <br/><p className='font-bold'>a.Cookies:</p> Our website may use cookies, which are small text files placed on your device to collect information about your preferences and browsing behaviour. You can control cookies through your browser settings; however, blocking cookies may affect certain features of the website.
<br/> <br/><p className='font-bold'>b.Analytics: </p>We may use analytics tools, such as Google Analytics, to collect non-personal information about website usage. These tools use cookies to track and analyse user behaviour on our website.

<br/> <br/><p className='font-bold'>5.Data Security:</p>
<br/> <br/>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of data transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute data security.

<br/> <br/><p className='font-bold'>6.Children's Privacy:</p>
<br/> <br/>Our website is not intended for children under the age of 13. We do not knowingly collect personal information from individuals under 13 years of age. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will promptly delete the information.

<br/> <br/><p className='font-bold'>7.Links to External Sites:</p>
<br/> <br/>Our website may contain links to external websites. Please note that we are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of those sites before providing any personal information.

<br/> <br/><p className='font-bold'>8.Changes to the Privacy Policy:</p>
<br/> <br/>We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting on this page. Your continued use of the website after such modifications signify your acceptance of the updated Privacy Policy.

<br/> <br/><p className='font-bold'>9.Contact Information:</p>
<br/> <br/>If you have any questions or concerns about this Privacy Policy or your personal information, please contact us at info@netballngr.org.
<br/> <br/>
Thank you for entrusting the Nigerian Netball Federation with your personal information. We are committed to protecting your privacy and ensuring a safe online experience while you explore and support the sport of netball in Nig
    </div>
    </div>
    
    </div>
  )
}

export default Privacy
