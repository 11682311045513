import React from 'react'
import Inspot from './Inspot'
import Faq from './Faq'

import imgbg from "../images/newspaper.jpg"
import LocalStories from './LocalStories';
import InterStories from './InterStories';


const News = ({ openNav, setOpenNav}) => {
  return (
    <div className= {openNav ? "open" : "close"}>
         <div className=' h-96  w-full m-auto  relative group font-yabari  overflow-hidden  '>
           <div  style={{ backgroundImage:`url(${imgbg})` }} className=' w-full h-full bg-center   flex  items-center justify-center  bg-cover '>
           <div className='absolute bg-green-950 opacity-80 w-full p-[100%]'></div>
              <span  className='md:text-xl lg:text-4xl font-yabari text-green-600 text-center flex min-[100px]:text-2xl flex-col items-center justify-between'>
                <h1 className='drop-shadow-2xl p-2'>NEWS AND UPDATES</h1>
             </span>
           </div>  
         </div> 
        <div className='   w-full m-auto  relative group font-yabari  bg-black overflow-hidden  '>
       <div className='gap-5 grid-cols-1'>
          <div><Inspot/></div>
          <div><LocalStories/></div>
          <div><InterStories/></div>
    </div>
    <div >
        <Faq/>
      </div> 
    </div>
    </div>
  )
}

export default News