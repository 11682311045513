import React, { useEffect, useState } from 'react';
import imgbg from "../images/resources.jpg";
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';

const Resources = ({ openNav, setOpenNav}) => {

  useEffect(()=>{
    AOS.init({duration: 1000})
 }, [])


 const [searchTerm , setSearchTerm] = useState(0)

 
 const inspots = [
  {
    id: 8,
    date : '09/02/2023',
    resource :   "Organization (NNF) Resources", 
    download: "download ",
    link:" https://drive.google.com/file/d/16miuje3_XagSTX4V9Z3_JH5JYPWRMG0i/view?usp=drivesdk"
  },
  {
     id: 7,
      date : '10/10/2023',
      resource :   "Coaching Resources", 
       download: "download "
    },
    {
      id :6,
      date : '18/10/2023',
      resource :   "Umpiring Resources", 
     download: "download "
    },
    {
      id: 5,
      date : '04/11/2023',
      resource :   "Player Resources", 
       download: "download "
    },
    {
      id: 4,
      date : '04/12/2023',
      resource :   "Our Strategic Brochure", 
       download: "download "
    },
    {
       id:35,
       date : '24/09/2023',
        resource :   "Officiating Resources",
       download: "download "
      },
      {
        id :2,
        date : '12/07/2023',
        resource :   "Educational Resources Media Resources", 
      download: "download "
      },
      {
        id :1,

        date : '12/08/2023',
        resource :" Media Resources", 
      download: "download "
      },
 
]

let tspots = inspots.sort((a , b) => new Date(...a.date.split('/').reverse() )- new Date(...b.date.split('/').reverse()));
let resos = tspots.reverse()
  return (
    <div>
       <div className={openNav ? "open" : "close"} >
       <div>
         <div className=' h-96  w-full m-auto  relative group font-yabari  overflow-hidden  '>
           <div  style={{ backgroundImage:`url(${imgbg})` }} className=' w-full h-full bg-center   flex  items-center justify-center  bg-cover '>
           <div className='absolute bg-green-950 opacity-80 w-full p-[100%]'></div>
              <span  className='md:text-xl lg:text-4xl font-yabari text-green-600 text-center min-[100px]:text-2xl flex  flex-col items-center justify-between'>
                <h1 className='drop-shadow-2xl'>RESOURCES</h1>
             </span>
           </div>  
         </div> 
           <div className='lg:px-[105px] lg:pb-[64px] md:px-28 bg-white text-green-600 text-center min-[100px]:p-8 min-[100px]:py-20 font-yabari flex items-center justify-center'>
           Our repository of resources we believe will be of great benefit to our members, players, coaches, umpires, officials, and fans alike. Click to download</div>
      <div className='relative  bg-white   font-yabari'>
       
         <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>DOWNLOADABLE RESOURCES</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
           <div className=" absolute lg:top-16  min-[100px]:left-0 min-[100px]:m-auto lg:right-0 md:top-12 min-[100px]:top-24  flex items-center  min-[100px]:justify-center lg:justify-end">
        <input className=' text-[12px ] text-black font-basc p-1 w-64 h-8 bg-white  placeholder-green-800 border-green-600 border-solid border-2 z=20' id="searchInput" type="text" placeholder="Search here..." onChange={(event) => {
            setSearchTerm(event.target.value);
          }} />
        </div>
         
        <div className=' lg:px-[105px] lg:py-40  md:px-28  z-10  md:pt-24   min-[100px]:p-8 min-[100px]:pt-40 grid  lg:gap-8 md:gap-8  min-[100px]:gap-8 lg:grid-cols-3 md:grid-cols-1'>
        {
            resos
              .filter((reso) => {
                if(searchTerm == ""){
                  return reso;
                }else if(reso.title.toLowerCase().includes(searchTerm.toLowerCase())){
                  return reso;
                }
              })
              .map((reso) =>(
          
          
                <div className=' border-solid border-8 border-green-600  relative '>
                  <div className='text-black flex flex-col justify-between gap-2 items-center ' data-aos="fade-up"> 
                    
                    <span className='text-green-600 lg:text-[11px] min-[100px]:text-xs text-center mt-8  '>{reso.resource}</span>
                    <span className='text-black font-basc lg:text-[12px]  min-[100px]:text-sm z-10'>{reso.date}</span>
                    
                  </div>
                  <Link to={reso.link}  className='relative left-0 m-auto right-0 top-8 flex items-center justify-center  p-6 w-52  bg-white' ><div className=' absolute  m-auto top-0  py-3 w-48 hover:w-52 duration-300 text-white font-semibold cursor-pointer bg-green-600 text-[10px] text-center uppercase '>{reso.download}</div></Link>
                    
                    </div>
                
                ))}
         
         </div>
         </div> 
      
         <div>
         </div>
         </div>
    </div>
    </div>
  )
}

export default Resources
