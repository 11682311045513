import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { Link } from 'react-router-dom';
import footerbg from "../images/footerbg2.png"
import { AiOutlineCompass, AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { BiCurrentLocation,  } from 'react-icons/bi';

import { BsFacebook, BsInstagram, BsTwitter, BsWhatsapp } from 'react-icons/bs'

const Footer = ({ openNav, setOpenNav}) => {
  const email =(e) =>{
    e.preventDefault()
  }
  return (
    <div className= {openNav ? "open" : "close"} >

    <div className='bg-green-600 pt-6 m-0 pb-72 relative overflow-hidden '>
   
                
                <div className='w-full '> <img src = {footerbg} className=' absolute w-[550px]  min-[100px]:-right-56 lg:right-0 top-24 z-10 ' /></div>        
                <div className=' absolute lg:top-2 md:top-8 min-[100px]:-top-2 flex items-center justify-between w-full'  >
          <span className=' lg:p-2 lg:px-12 lg:mr-2 bg-green-950 md:p-2 md:px-12  min-[100px]:p-1  min-[100px]:px-4'></span>
          <form
                    for="email"
                    className='relative z-10 lg:text-sm m-2 lg:mt-12   min-[100px]:mt-6  min-[100px]:mr-2 md:text-sm  min-[100px]:text-md leading-7  text-green-950  font-yabari md:grid md:grid-cols-1' >
           
            <div className=' min-[100px]:text-md  '>
            NEWS LETTER SIGN UP
            <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder='yourname@example.com'
                    className=" placeholder-green-800 font-basc z-10 flex  min-[100px]:absolute  min-[100px]:w-screen  min-[100px]:-ml-10  lg:ml-0 md:w-[250px] lg:w-[275px] border-b-2 border-white  focus:border-green-950 bg-green-600 bg-opacity-0  text-base text-white outline-none py-1  leading-7 transition-colors duration-200 ease-in-out"
                  />  
                  </div> 
                <button onClick={email} className='relative text-sm lg:top-16 underline font-basc flex end-0  min-[100px]:top-16 justify-start '>Sign Up</button>
          </form>
          <span className=' bg-green-950  md:p-2 flex grow min-[100px]:p-1'></span></div> 
         <div className=' flex items-center justify-between  mb-12'  >
          
       </div> 
               
            
             
      <div className='mt-40 flex items-center justify-between  mb-8 lg:mx-24 xl:mx-24  md:mx-20 min-[100px]:mx-4 font-basc'  >
          
      <div className='md:grid md:grid-cols-1  z-10 '>
      
         <div  className=" flex flex-col m-2 p-2 text-sm md:pb-2 ">
            <h1 className=" lg:text-sm md:text-sm  min-[100px]:text-sm  text-green-950  pb-2  font-yabari ">QUICK LINKS</h1>
            <a href=' https://netball.sport/  ' className="  text-white hover:underline  pb-2 ">World Netball</a>
            <a href=' https://nigeriaolympic.org/ ' className="  text-white hover:underline  pb-2 ">Nigeria Olympic Committee</a>
            <a href=' https://olympics.com/ioc ' className="  text-white hover:underline  pb-2 ">International Olympic Committee</a>
            <a  href='https://youthandsports.ng/' className="  text-white hover:underline  pb-2 ">Federal Ministry of Youth and Sports Development</a>
            <a  href='https://africaolympic.com/en/home/' className="  text-white hover:underline  pb-2 ">Association of National Olympic Committees of Africa</a>
          
           </div>
           <div  className="grid  md:grid-cols-1 lg:grid-cols-2 gap-0 ">
          <div  className=" m-2 p-2 text-sm md:pb-2 ">
              <h1 className=" lg:text-sm md:text-sm  min-[100px]:text-sm text-green-950  pb-2  font-yabari ">CONTACTS</h1>
            <h1 className="  text-white  pb-2 flex gap-2 "> <p>info@netballngr.org</p></h1>
            <h1 className="  text-white  pb-2 flex gap-2"><p> +234 (0) 803 300 0227 <br/> +234 (0) 803 600 0559</p></h1>
            <h1 className="  text-white  pb-2 flex gap-2"><p> 9 Usuma Close, Maitama, Abuja, Nigeria</p></h1>
           </div>
           <div className='flex flex-col min-[100px]:pb-2 lg:pb-12 m-2 p-2  text-white'>
           <h1 className=" lg:text-sm md:text-sm  min-[100px]:text-sm text-green-950  pb-2  font-yabari ">SOCIALS</h1>
           <div className='flex items-center  gap-4'>
       <a href='https://instagram.com/netballngr?igshid=MzRlODBiNWFlZA=='> <BsInstagram size={20}/> </a>
       <a href=' https://twitter.com/NetballNgr?s=20'> <BsTwitter size={20}/> </a>
       <a href='https://www.facebook.com/nigerianetball'> <BsFacebook size={20}/> </a>
       </div>
     </div>
           </div>
         <div  className=" m-2 p-2 text-sm  flex flex-col md:pb-2">
            <h1 className=" lg:text-sm md:text-sm  min-[100px]:text-sm  text-green-950  pb-2  font-yabari ">POLICIES</h1>
         <Link to= "/termsofuse"   onClick={() =>{ window.scrollTo(0,0)} } className="   text-white  hover:underline pb-2 "> Terms of Use</Link> 
         <Link to= "/privacypolicy" onClick={() =>{ window.scrollTo(0,0)} }  className="  text-white  hover:underline pb-2 "> Privacy Policy </Link>
        </div>
        <div  className=" m-2 p-2 text-sm flex flex-col md:pb-2">
            <h1 className=" lg:text-sm md:text-sm  min-[100px]:text-sm  text-green-950  pb-2  font-yabari ">EXPLORE FURTHER</h1>
         <Link to="/activities"  onClick={() =>{ window.scrollTo(0,0)} } className=" hover:underline  text-white  pb-2 "> Activities </Link> 
         <Link to="/resource"  onClick={() =>{ window.scrollTo(0,0)} } className=" hover:underline text-white  pb-2 "> Resources </Link>
        </div>

       
         <div  className=" p-2 flex m-2 min-[100px]:flex-col  min-[100px]:gap-12  md:pb-2 ">
         <h1 className="  text-sm  text-white  pb-2 ">Copyright © 2023. <br/> NIGERIAN NETBALL FEDERATION. <br/> All rights reserved.</h1>
         <Link to="/sitemap"  onClick={() =>{ window.scrollTo(0,0)} } className="  text-sm  text-white  lg:pt-0  pb-2 "> Sitemap </Link>
         </div>
      
         </div>
         
         </div>
    </div>
   
   
    </div>
  )
}

export default Footer