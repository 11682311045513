
import React,  { useEffect, useState } from "react";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
  } from "@tanstack/react-table";
import AOS from 'aos'
import 'aos/dist/aos.css'
import imgbg from "../images/img4.jpg"




  const defaultData = [
    {
      order:"1st",
      programme:"Pre-launch Communications",
      pt:"Hi, 234, Here’s Netball",
      ete:"March, 2023",
    },
    {
      order:"2nd",
      programme:"Press Conference",
      pt:"N/A",
      ete:"June, 2023",
    },
    {
      order:"3rd",
      programme:"Launch Event",
      pt:"Net, Set, Go!",
      ete:"September, 2023",
    },
    {
      order:"4th",
      programme:"Capacity Building",
      pt:"A.C.E.",
      ete:"September, 2023",
    },
    {
      order:"5th",
      programme:"Infrastructure Development",
      pt:"N-IDS",
      ete:"October, 2023",
    },
    {
      order:"6th",
      programme:"Talent Identification",
      pt:"T.I.N.C.",
      ete:"October, 2023",
    },
    {
      order:"7th",
      programme:"National Team Building",
      pt:"Nigerian NetStars",
      ete:"November, 2023",
    },
    {
      order:"8th",
      programme:"Tournaments",
      pt:"The Junior N.E.T.S. Tournament The N.E.T.S. Tournament",
      ete:"",
    },
    {
      order:"9th",
      programme:"National League",
      pt:"The NetQueens League",
      ete:"November, 2023",
    },

  ];

  const columnHelper = createColumnHelper();

  const columns = [

    columnHelper.accessor("order", {
        header: "Order",
      }),
      columnHelper.accessor("programme", {
        header:"Programme",
      }),
      columnHelper.accessor("pt", {
        header: "Programme Title",
      }),
      columnHelper.accessor("ete", {
        header: "Estimated Time of Execution",
      }),
    
  ]

const Activities = ( {openNav, setOpenNav}) => {
   
    
      useEffect(()=>{
        AOS.init({duration:1000})
     }, [])
    
     const [data, setData] = useState(() => [...defaultData]);
     const table = useReactTable({
       data,
       columns,
       getCoreRowModel: getCoreRowModel(),
     });
   
      return (
        <div>
         <div   className= {openNav ? "open" : "close"}>
         <div className=' h-96  w-full m-auto  relative group font-yabari  overflow-hidden  '>
           <div  style={{ backgroundImage:`url(${imgbg})` }} className=' w-full h-full bg-center   flex  items-center justify-center  bg-cover '>
           <div className='absolute bg-green-950 opacity-80 w-full p-[100%]'></div>
              <span  className='md:text-xl lg:text-4xl font-yabari text-green-600 text-center flex min-[100px]:text-2xl flex-col items-center justify-between'>
                <h1 className='drop-shadow-2xl'>ACTIVITIES</h1>
             </span>
           </div>  
         </div> 
           <div className='lg:px-[105px] lg:pb-[64px] md:px-28 bg-white text-green-600 text-center min-[100px]:p-8 min-[100px]:py-20 font-yabari flex items-center justify-center'>
           Keep up with the events surrounding netball in Nigeria</div>
      
          </div>
          <div>  
       <div className='lg:px-[105px] lg:pb-[64px] md:px-28 bg-white text-green-600 text-center min-[100px]:p-8 min-[100px]:py-20 font-yabari flex items-center justify-center'>
       <table className="border-solid border-2  min-[100px]:p-2 border-green-600">
      <thead className="border-solid border-b-2 border-green-600 lg:text-lg  min-[100px]:text-[9px] " >
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="pb-4 ">
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}  className="py-6 lg:text-lg  min-[100px]:text-[8px] ">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
       </div>
     </div>        
       </div>
        
      )
    }

export default Activities
