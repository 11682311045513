import { useEffect, useState } from 'react'
import '../index.css';
import img1 from "../images/bnnwhite.png"
import img2 from "../images/img2.jpg"
import img3 from "../images/netbw.jpg"
import tinc from  "../images/tinc.png"
import ace from  "../images/ace.png"
import hithere from  "../images/234.png"
import netprotect from  "../images/netprotect.png"
import nettg from  "../images/nettg.png"
import nettp from  "../images/nettp.png"
import queen from  "../images/queen.png"
import learn from  "../images/learn.png"
import p4f from  "../images/playfees.png"
import netcourt from  "../images/netcourt.png"
import netcourt2 from  "../images/netcourt2.png"
import empi from "../images/empower.png"
import Faq from './Faq';
import React from 'react'
import {BsChevronCompactLeft,BsChevronCompactRight, BsDashLg} from 'react-icons/bs'
import { BsTriangleFill } from 'react-icons/bs'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Navigation, Pagination } from 'swiper';
import AOS from 'aos'
import 'aos/dist/aos.css'






const Bnn = ( {openNav, setOpenNav}) => {
 

  const empower=[
    {
    id:1,
    stage:"STAGE 1",
    topic:"The Out-Of-School Girls",
    desc:<p>a. Age Range: 11 years to 18 years <br/> <br/>
    b. Life Challenges: <br/> <br/>
    * No finances to go to school <br/> <br/>
    * No interest in schooling <br/> <br/>
    * Being hindered from schooling by family/
    guardians <br/> <br/>
    c. The NNF’s Contribution: <br/> <br/>
    * Access to play, learn, advance in skill through 
    public NNF-instituted Netball Centres <br/> <br/>
    * Exposure to NNF events that enlighten them 
    on the essence of schooling <br/> <br/>
    * Media campaigns that drive the message of 
    the importance of academics and its interplay with the netball game <br/> <br/></p>,
  },
  {
    id:2,
    stage:"STAGE 2",
    topic:"The Junior Secondary School Girls ",
    desc:<p>
    a. Age Range: 11 to 14 years <br/>  <br/>
b. Life Challenges: <br/>  <br/> <br/>  <br/>
* No finances to advance to senior secondary education <br/>  <br/>
* Confusion on career direction <br/>  <br/>
c. The NNF’s Contribution: <br/>  <br/>
* A scholarship scheme for the best performing netball talents in academics that 
covers for their senior secondary school 
education. The goal is to see that over 60 
scholarships are awarded in the space of 
4 years <br/>  <br/>
* Career advice sessions during NNF’s Social 
Impact Programmes <br/>  <br/>
* Selections to join the National U14 Team for 
exceptionally developing players, and be 
covered by the NNF Life Insurance Scheme, 
taking care of the girl’s health and accident 
financial needs. This serves as a great motivator to engage in the sport and do well 
academically too.
               </p>,
  },
  {
    id:3,
    stage:"STAGE 3",
    topic:"The Senior Secondary School Girls ",
    desc:<p> a. Age Range: 15 to 18 years <br/><br/>
    b. Life Challenges: <br/><br/>
    * No finances to further education beyond 
    secondary school <br/><br/>
    * Teenage pregnancy <br/><br/>
    * Early marriages <br/><br/>
    * Indulgences in drug abuse, sex abuse, et 
    cetera <br/><br/>
    * Truancy & Unwillingness to continue education. <br/><br/>
    * Illegal migration, and human trafficking involvements <br/><br/>
    c. The NNF’s Contribution: <br/><br/>
    * Holding social impact engagement programmes, twice a year, with the aim to equip 
    these girls with skills and knowledge around 
    Health, Digital Literacy / Digital Confidence, 
    Financial Literacy / Financial Confidence, 
    Education (Vocational Skills), and Civic Responsibility. The target is to impact over 200 
    girls directly (with testimonials and evidence 
    of new businesses started, or concrete engagements initiated based on skills received) 
    and over 5,000 girls indirectly. <br/><br/>
    * Selections to join the National U18 Team for 
    exceptionally developing players, and be 
    covered by the NNF Life Insurance Scheme, 
    taking care of the girl’s health and accident 
    financial needs. This serves as a great motivator to engage in the sport and do well academically too.</p>,
  },
  {
    id:4,
    stage:"STAGE 4",
    topic:"The Post-Secondary School Girls",
    desc:<p>   a. Age Range: 19 years and above. <br/><br/>
    b. Life Challenges:<br/><br/>
    * Unemployment<br/><br/>
    * Lack of finances to finish tertiary education<br/><br/>
    c. The NNF’s Contribution:<br/><br/>
    * Participation in the NNF-Designed and 
    Overseen Elite Tournament where they can 
    join any netball team, register for the tournament, and play to win.<br/><br/>
    * Exposure to personalities and environments that can significantly alter the way 
    they spot and take advantage of business 
    opportunities, through the NNF’s netball 
    events and communication<br/><br/>
    * Selections to join the National U14 Team for 
    exceptionally developing players, and be 
    covered by the NNF Life Insurance Scheme, 
    taking care of the girl’s health and accident 
    financial needs. This serves as a great motivator to engage in the sport and do well 
    academically too.<br/><br/>
    * Provision of entrepreneurship training, mentorship, and business incubation-to-develop skills, thereby supporting 
    young girls in starting their own ventures. <br/><br/>
    This will be enhaced through the establishment of partnerships with employers 
    for job opportunities and the creation of 
    awareness campaigns to promote entrepreneurship as a viable career option for 
    the players.</p>,
  },
  

]

const rebirth= [
  {
    id: 1,
    content: <div className='   w-full m-auto  relative group font-yabari  overflow-hidden  '>
    <div className='' >
    
      <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
       <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
       <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>GROW</span>
       <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
       </div>
       <div  className=' lg:pt-28 min-[100px]:pt-14 p-8  lg:p-[105px] bg-black'>
       <div className='  pb-4 flex flex-col items-center justify-between   mb-16'data-aos="fade-up ">
         <h1 className='text-center text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari '>TINC</h1>
         <img src={tinc}/>
         <p className=' text-white lg:text-lg font-thin  md:text-xs min-[100px]:text-sm m-2 font-basc'>
         Junior Talents (players aged 11 to 14), Intermediate Talents
(players aged 15 to 18), and Elite Talents (players aged 19
and above) by discovering, empowering, and equipping
them with netball and netball-adjacent vocational skills,
through its Junior Talent Identification Programme, Intermediate
Talent Identification Programme, and Senior
Talent Identification Programme. This programme also
incorporates the sub-programme of establishing netball
centres for training, grassroots games, and talent scouting
in secondary schools, state sports parks, LGA sports
parks, sports arenas of military and paramilitary organizations,
and conscience sports park projects. All 3 talent
identification Programme come under the NNF’s “T.I.N.C”
programme. T.I.N.C. stands for “Talent Identification, Netball
Centres” .The chief roadmap for this Programme design
sees the NNF engage 2,200 talents in about 30 centres
by the end of year one (2023), 6,000 talents in about
60 centres by the end of year two (2024), 8,800 talents
in about 80 centres by the end of year three (2025), and
12,000 talents in about 100 centres by the end of year four
(2026). </p></div>

         <div className='  pb-4 flex flex-col items-center justify-between  mb-16 'data-aos="fade-up">
         <h1 className='text-center text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari '>ACE</h1>
         <img src={ace}/>
         <p className=' text-white lg:text-lg font-thin  md:text-xs min-[100px]:text-sm m-2 font-basc'>
         Capacity, in the form of coaches, umpires (referees) and development
officials, three of whom will be integral to every other aspect of the
NNF’s Programme for the 4 years, as they are involved in training the
talents-in-training under the talent identification Programme, training
the games masters (PhysEd teachers from secondary schools where
NNF call a Netball Centre) who eventually train the talents in the secondary
schools, officiating all NNF-organized matches, and overseeing/
reporting on the progress of training at these netball centres. The Capacity
Building Programme of the NNF is its “A.C.E.” project. A.C.E. stands
for “Advancing Capacity Excellence”.
The chief roadmap for this Programme design sees the NNF train/upgrade
100 officials in 4 workshop sessions by the end of year one (2023),
200 officials in 8 workshop sessions by the end of year two (2024), 300
officials in 12 workshop sessions by the end of year three (2025), and 400
officials in 16 workshop sessions by the end of year four (2026).
         </p></div>
         <div className='  pb-4 flex flex-col items-center justify-between  mb-16 'data-aos="fade-up">
         <h1 className='text-center text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 mb-10 font-yabari '>LEAGUE & TOURNAMENTS</h1>
         <div className='grid grid-cols-3'>
         <img src={queen}/>
         <img src={nettg}/>
         <img src={nettp}/>
         </div>
         <p className=' text-white lg:text-lg font-thin  md:text-xs min-[100px]:text-sm m-2 font-basc'>
         Tournaments and League, in the form of the junior and intermediate tournaments
and the elite league for discovered talents.
The elite league, titled “The NetQueens League” will involve players, aged
19 and above, from each centre which forms the participating teams. The
intermediate tournament, titled “The N.E.T.S. Tournament” will involve
players, aged 15 to 19, from each centre which forms the participating
teams. The junior tournament, titled “The Junior N.E.T.S. Tournament” will
involve players, aged 11 to 14, from each centre which forms the participating
teams.
N.E.T.S. stands for “Netball Excellence Tournament Series”
The tournaments will exist to spur healthy competition between the clubs,
develop a sense of pride in the winning teams, improve the self-esteem
of the involved players, create an avenue for the world to spot great talent
and follow up with them, and create a natural urge to want to do
better next time, thus engendering increased focus and enhanced team
work for the next season.
The chief roadmap for this Programme design sees the NNF oversee at
least 50 games played by 25 teams by the end of year one (2023); 120
games played by 40 teams by the end of year two (2024); 240 games
played by 60 teams by the end of year three (2025), and 400 games played
by 80 teams by the end of year four (2026).
         </p></div>




         <div className='  pb-4 flex flex-col items-center justify-between  mb-16 'data-aos="fade-up">
         <h1 className='text-center text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari '>LEARN</h1>
         <img src={learn}/>
         <p className=' text-white lg:text-lg font-thin  md:text-xs min-[100px]:text-sm m-2 font-basc'>
         Social Impact Creation, on the junior and intermediate talents.
NNF believes in the wholesome development of the girl child,
and so, has designed to partner with development organizations
currently in the social development space, doing good work in
equipping young girls with critical and relevant knowledge that
directly impacts their well-being and general progress.
For these first 4 years, the NNF has designed this programme in
an effort to utilize netball to combat these following realities in
the lives of the average Nigerian Girl Child in the region: <br/><br/>
» Health Challenges: Nigeria faces significant health challenges,
with a high prevalence of infectious diseases like
malaria and tuberculosis. Additionally, maternal and child
mortality rates in the region is among the highest in the
country. In 2018, the infant mortality rate was 132 deaths
per 1,000 live births, and the under-five mortality rate was
172 deaths per 1,000 live births. <br/><br/>
» Digital Literacy / Digital Confidence: Digital penetration
rates in Nigeria is low, with only about 47% of the population
having access to the internet. The figure for the girl
child could be as high as 90%. As a result, many people
lack the skills and knowledge to use digital technologies
effectively. This greatly hampers the potentials for social
inclusion for these girls. <br/><br/>
» Financial Literacy / Financial Confidence: Nigeria has high
levels of poverty and unemployment, with 23.3% of the population
living below the poverty line. Inadequate financial
literacy and confidence are significant barriers to economic
development and financial inclusion. <br/><br/>
» Education (Vocational Skills): Nigeria has the highest number
of out-of-school children in the country, with an estimated
43.5 million children out of school. Access to quality
education is a fundamental right and serves as a means of
reducing poverty and promoting economic growth
» Civic Responsibility: It is not news that Nigeria has experienced
incessant security challenges in recent years, with
incidents of violence and conflict leading to a breakdown
in social cohesion. Over 25,000 deaths were recorded between
January 2021 and June 2023, with the UN stating that
over 8.4 million persons are in critical need of humanitarian
and protection assistance. <br/><br/>
The drive of the NNF to build the sport from the grassroots necessitates
its involvement in ensuring that the persons being
impacted by netball are as well provided with the social tools to
continue their improvement in the sport. One cannot go without
the other. By this foregoing, the NNF has chosen to focus on
these five primary impact directions relevant to young Nigerian
girls: <br/><br/>
* Health <br/><br/>
* Digital Literacy / Digital Confidence <br/><br/>
* Financial Literacy / Financial Confidence <br/><br/>
* Education (Vocational Skills) <br/><br/>
* Civic Responsibility <br/><br/>
This impact creation project is called “L.E.A.R.N.” (Liberation, Enlightenment,
Achievement, Resilience, Nurturing). L.E.A.R.N. will
be executed using zone-based system, which enables the NNF
roll out 3-day enlightenment workshops, 28-day direct impact
monitoring, and 365-day indirect impact monitoring and measurement.
The chief roadmap for this Programme design sees 6 events held
by the end of year two (2024), 12 events held by the end of year
three (2025), and 18 events held by the end of year four (2026).
         </p></div>

         <div className='  pb-4 flex flex-col items-center justify-between   mb-16'data-aos="fade-up ">
         <h1 className='text-center text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari '>HI HERE'S NETBALL 234</h1>
         <img src={hithere}/>
         <p className=' text-white lg:text-lg font-thin  md:text-xs min-[100px]:text-sm m-2 font-basc'>
         Viewership, of the netball game in Nigeria. Through its
communications project called, “Hi, Here’s Netball
234!”, the NNF aims at ensuring that year by year, event
by event, match by match, person by person, the awareness,
knowledge, appreciation, and participation desire
of and for the sport grows and spreads. The main tools
designed to achieve this is major events media coverage
sponsorships, and a series of calculated online marketing
campaigns via social media. <br/> <br/>
The Nigerian Netball Federation (NNF) recognizes the
importance of creating awareness for the sport of netball
in Nigeria. Given its status as a minority sport within
the country, the NNF is dedicated to establishing its
popularity and breaking new ground to ensure that netball
kicks off in full force. To achieve this ambitious goal,
the NNF has implemented a concerted effort through an
awareness, visibility, and adoption program, separate
from its other communication initiatives. This program
is specifically designed to generate significant attention
and encourage the Nigerian public to take netball seriously.
Understanding the need for a major breakthrough, the
NNF has meticulously curated a comprehensive set of
activities to capture the nation’s interest. The primary
objective is to drive immediate engagement in the sport,
appealing to a broad audience. One of the strategies
employed by the NNF is the incorporation of glamour. <br/> <br/>
In line with this, Nigerian-born netball players currently
representing other countries will be brought together to
form a “diaspora team.” This team will compete against
a “home team” during the official launch of the NNF,
creating an exciting spectacle that resonates with the
theme of glamour.  <br/> <br/>
To further enhance the sport’s visibility, the NNF plans
to appoint some of these Nigerian-born netball players
as NNF Ambassadors. This strategic move aims to
leverage their success and influence to promote netball
within the country. By having accomplished athletes as
ambassadors, the NNF intends to establish a positive
association with the sport, inspiring aspiring netball
players and capturing the attention of the wider public.
Additionally, the NNF recognizes the significance of
public participation and aims to reward it through the
allocation of prize monies. By incentivizing engagement,
the NNF hopes to foster immediate interest and encourage
Nigerians to actively embrace netball. This approach
complements the individual efforts being made
for the various NNF programs, ensuring that the sport
gains the attention it deserves across the board. <br/> <br/>
In conclusion, the NNF is fully committed to establishing
netball as a popular sport in Nigeria. The dedicated
awareness, visibility, and adoption program represents
a major initiative separate from the communication efforts
for other NNF programs. By incorporating glamour,
utilizing Nigerian-born netball players as ambassadors,
and rewarding public participation through prize
monies, the NNF aims to generate immediate engagement
and ignite enthusiasm for netball throughout the
country. <br/> <br/>
The chief roadmap for this Programme design sees
the NNF garner over 2,000 eyeballs per month on netball-
related affairs in the country by the end of year one
(2023), over 30,000 eyeballs per month on netball-related
affairs in the country by the end of year two (2024),
over 300,000 eyeballs per month on netball-related affairs
in the country by the end of year three (2025), and
over 1.1 million eyeballs per month on netball-related
affairs in the country by the end of year four (2026).
(2026). </p></div>

</div>
<div className=' bg-green-950 text-white font-yabari  lg:p-32 min-[100px]:p-8'> 
The “Grow” strategy emphasizes
strategy imperatives that must
never remain dormant, inactive,
or static. The emphasis is in the
word, “grow”. The desire is that
the yearly strategic goals for
any of the programmes keep
significantly increasing year by
year within these 4 years.
</div>
</div>
  },
  {
    id: 2,
    content:  
    <div className='   w-full m-auto  relative group font-yabari  overflow-hidden  '>
 <div className='' >
 
   <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
    <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
    <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>BUILD</span>
    <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
    </div>
    <div  className=' lg:pt-28 min-[100px]:pt-14 p-8  lg:p-[105px] bg-black'>
    <div className='  pb-4 flex flex-col items-center justify-between   mb-16'data-aos="fade-up ">
      <h1 className='text-center text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari '>National Teams<br/> <p className='text-sm opacity-80'>U14, U18 & U21</p></h1>
      
      <p className=' text-white lg:text-lg font-thin  md:text-xs min-[100px]:text-sm m-2 font-basc'>
      National Teams, comprising the senior team (The Nigerian
Net Stars, aged 19 and above), the Youth / U21 team (The
Nigerian Net Starlets, aged 18 to 21, and made up of
players from the senior team and the U18 team that are
within the age range), the U18 team (The Nigerian Net
Starlings, aged 15 to 18) and the U14 team (The Nigerian
Net Starbursts, aged 11 to 14). The national teams building
project will be centred around team synergy, support,
and access to aid, whereby team member make-ups will
be scouted from talents and stars from netball centres
within the country (housing talents-in-training under the
talent identification programme) and talents from outside
Nigeria who are eligible to play for the national teams.
The project will also be driven by the acquisition of global
game points – by the hosting of international netball
games on home soil – in order to provide these national
teams, the best springboard to be quickly ranked globally,
and pursue laurel excellence accordingly. The goal is to
be among the top 5 ranking netball teams in Africa and
the top 10 ranking teams globally in 4 years’ time.
The chief roadmap for this programme design sees the
NNF scout and equip about 21 players and 3 coaches and
4 support staff for the national teams by the end of year
one (2023), about 39 players and 8 coaches and 12 support
staff for the national teams by the end of year two (2024),
about 84 players and 13 coaches and 24 support staff for
the national teams by the end of year three (2025), and
about 91 players and 15 coaches and 28 support staff for
the national teams by the end of year four (2026). </p></div>
<div className='  pb-4 flex flex-col items-center justify-between   mb-16'data-aos="fade-up ">
      <h1 className='text-center text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari '>Players Welfare</h1>
      <div className='grid grid-cols-2'>
     
     <img src={netprotect}/>
     <img src={p4f}/>
     </div>
      <p className=' text-white lg:text-lg font-thin  md:text-xs min-[100px]:text-sm m-2 font-basc'>
      Players Welfare, comprising a scholarship programme called “Encourage-
Them-Young” and an insurance programme called “Net-
Protect”. The scholarship programme is designed to build and
maintain a commitment to the welfare of junior and intermediate
talents, whereby netball talents-in-training who excel academically
beyond their peers are offered scholarships to finish up the rest
of their secondary school education. The insurance programme is
designed to build and maintain a commitment to the welfare of all
players actively playing in the Nigerian national team (at all levels),
by providing them a Group Life Insurance scheme that covers
health and accident costs for the players for the duration of their
play for the nation.<br/><br/>
The chief roadmap for this programme design sees 21 players covered
by the insurance in year one (2023); 16 scholarships awarded
and 49 players covered by the insurance by year two (2024); 32
scholarships awarded and 84 players covered by insurance by year
three (2025); and 64 scholarships awarded and 91 players covered
by insurance by year four (2026).

Players Welfare
91 Players
LIFE INSURANCE COVER 64 Players
SCHORLARSHIP AWARDS
</p></div>
<div className='  pb-4 flex flex-col items-center justify-between   mb-16'data-aos="fade-up ">
      <h1 className='text-center text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari '>Netball Infrastructure<br/> <p className='text-sm opacity-80 '>Netball Complexes and Centres</p></h1>
      <div className='grid grid-cols-2 p-12'>
     
      <img src={netcourt}/>
      <img src={netcourt2}/>
      </div>
      <p className=' text-white lg:text-lg font-thin  md:text-xs min-[100px]:text-sm m-2 font-basc'>
      Netball Courts, comprising two 4-court complexes (one in Abuja, and
another in Enugu), Indoor courts across the 36 states of Nigeria (and
the FCT), and a court for every Netball Centre established in the talent
identification programme. The goal with these other courts is to
have the NNF build the courts established in the regular secondary
school centres.


</p></div>

</div>
    <div className=' bg-green-950 text-white font-yabari lg:p-32 min-[100px]:p-8'> 
    The “Build” strategy emphasizes strategy
imperatives that must enter existence all
at once, and not cease existing once it
starts. This strategy favours structures and
systems, and the desire is that the yearly
strategic goals for any of the programmes
keep fuelling the maintenance and
improvement of those established systems.
</div>
    </div>  
  },
  {
    id: 3,
    content:  <div className='   w-full m-auto  relative group font-yabari bg-black overflow-hidden  '>
    <div className='' >
    
      <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
       <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
       <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>SUSTAIN</span>
       <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
       </div>
       <div className='  pb-4 flex flex-col items-center justify-between lg:p-32 min-[100px]:p-8  min-[100px]:pt-20  mb-16'data-aos="fade-up ">
       <p className=' text-white lg:text-lg font-thin  md:text-xs min-[100px]:text-sm m-2 font-basc'>
       The NNF, within these 4 years, will be sustaining: <br/><br/>
1. Talent Relationships: relationships with discovered talents/players of
all ages. <br/><br/>
2. Developers Relationships: relationships with trained coaches, games
masters, umpires and development officials  <br/><br/>
3. Oversight Relationships: relationships with organizations that the NNF
will report to, including, the IOC, NOC, ANF, INF, and Federal Ministry of
Youth and Sports Development  <br/><br/>
4. Constitutional Relationships: relationships with bodies and individuals
that offer licence for the NNF to meet, reach agreements with, and
operate with schools and communities to establish netball centres. <br/><br/>
5. Grant Partnership Relationships: relationships with organizations and
individuals who will offer grants for project executions, especially
around talent identification and social development.  <br/><br/>
6. Project Execution Partnership Relationships: relationships with organizations
and individuals whom the NNF will partner with to execute
its netball-adjacent social development programmes like the guidance
counselling and vocational skills training.  <br/><br/>
7. Value-in-Kind Sponsorship Relationships: relationships with organizations
and individuals who shall serve as the NNF’s supply partners.
8. Project Financial Sponsorship Relationships: relationships with organizations
that provide funding for the execution of any of the NNF’s
programmes. </p>
       </div> 
       <div className=' bg-green-950 text-white font-yabari lg:p-32 min-[100px]:p-8  '> 
       The “Sustain” strategy emphasizes
strategy imperatives that must never
cease. They are strategic relationships
designed to last infinitively. The
strategies cover the 4 years at once,
and is not taken in bits; designed as
well for easy renewals to promote
continuity for the next tranche of years
the NNF shall exist.
</div>
       </div> 
  }
]
 
const [open, setOpen] = React.useState(null);
const [openr, setOpenr] = React.useState(null);
const [opens, setOpens] = React.useState(null);
const [openrn, setOpenrn] = React.useState(null);  
const toggle = id => setOpenrn(openrn === id ? undefined : id)
  useEffect(()=>{
    AOS.init({duration: 1000})
 }, [])

 console.log(open);
  return (
    <div className={openNav ? "open" : "close"} >
        <div className='  min-[100px]:h-[520px] md:h-[700px] lg:h-[800px] w-full m-auto  relative group font-yabari  overflow-hidden'>
        <div style={{ backgroundImage:`url(${img2})` }} className='  h-full bg-center bg-cover  duration-500'></div>
        <div className='relative flex justify-center'> < img src={img1 }  className= ' absolute lg:right-28  min-[100px]:bottom-0 min-[100px]:m-auto lg:m-2  lg:w-[500px] min-[100px]:w-[270px] lg:bottom-28 duration-500'/></div>
       <div className=  " noShadow "></div>
      
    </div>
    <div className='lg:px-[105px] lg:pb-[64px] md:px-28 bg-black text-green-600  min-[100px]:p-8 min-[100px]:py-20 font-yabari  items-center justify-center'>
        Birthing Netball Nigeria is the overall strategy brand name for
the 4-Year (2023 to 2026) Netball Masterplan of the Nigerian
Netball Federation, NNF
<br/> The brand direction comes from a culmination of the 3 big
ideas the organization had at infancy, on what to do about netball <br/>
in Nigeria. These 3 big ideas are: <br/> <br/>
<span  className='text-white '>
<span>• Reviving netball in Nigeria by building from the grassroots </span> <br/><br/>
<span> • Nurturing a Nigerian netball ecosystem</span> <br/><br/>
<span >• Establishing Novice-to-Elite pathways</span> <br/><br/>
</span>
These ideas gave birth to the working slogan, mission statement,
2026 vision, and strategic ambitions of the NNF, as given: </div>

<div>
<div onClick={() => setOpen(!open)} style={{ backgroundImage:`url(${empi})` }} className='cursor-pointer   h-full bg-center bg-cover p-32  duration-500 font-yabari lg:text-5xl min-[100px]:text-3xl min-[100px]:flex items-center flex-col justify-center text-white shadow-lg  '><span>Empower
<p className='text-green-600'>AMBITION</p> </span></div>
<section className={ !open  ? "open" : "close"}>
<div className=' bg-black text-green-600 font-yabari  lg:p-32 min-[100px]:p-8'> 
The strategic plan to Empower is built on a 
commitment to have the NNF be a positive 
change agent for young girls in Nigeria, as 
the sport is the only female-only sport being 
played in Nigeria. Presently, there are 37.8 
million girls aged 15 to 35 in Nigeria (Statista, 2021), which serves as the catchment age 
for our netball sports and empowerment programmes. Out of this number, 4.1 million are 
not in school (representing 38%), and 1.6 million are completely unemployed (representing 4.4%).
These numbers are staggering, and the NNF 
is committed to doing its part to reduce these 
rates. The strategy is to follow a 4-tiered approach to tackle the challenges faced by Nigerian girls at different stages of their lives, <br/><br/>

<div onClick={() => setOpens(!opens)} className='cursor-pointer border-solid border-2 p-6 border-green-600 text-center hover:bg-green-600 hover:text-black duration-300'>  STAGES </div>
  </div>
  <div  className={!opens  ? "open" : "close"}>
  {empower.map((emp, index ) =>(
  <div  key={index}  className=' w-full m-auto  relative group font-yabari  overflow-hidden  '>
       <div className='' >
       
         <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>{emp.stage}</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
          </div>
          <div  className=' lg:pt-28 min-[100px]:pt-14 p-8 pb-12 lg:px-[105px] bg-black'>
          <div className='  flex flex-col items-center justify-between  'data-aos="fade-up ">
          <p className=' text-white lg:text-lg min-[100px]:text-sm font-basc'>
         <h1 className='text-green-600 font-yabari'> {emp.topic} </h1><br/> <br/>
 {emp.desc}
            </p>
  </div> </div> 
            </div>  
  ))}       
   </div>          
          </section>
        
            

<div onClick={() => setOpenr(!openr)} style={{ backgroundImage:`url(${img3})` }} className='cursor-pointer   h-full bg-center bg-cover p-32  duration-500 font-yabari lg:text-5xl min-[100px]:text-3xl min-[100px]:flex items-center flex-col justify-center text-white shadow-lg  '><span>Rebirth
<p className='text-green-600'>AMBITION</p> </span></div>
<section  className={ !openr  ? "open" : "close"}>
<div className=' bg-black text-green-600 font-yabari  lg:p-32 min-[100px]:p-8'> 
To achieve these strategic ambitions, the NNF
has designed a 4-year strategy aimed at establishing
the systems that will aid achievement.
This strategy is its Rebirth Strategy,
comprising 3 parts: <br/><br/>
<span className=' text-green-600 text-center'>
<div onClick={() => toggle(rebirth[0].id)} className='border-solid border-2 p-6 border-green-600 text-centercursor-pointer  hover:bg-green-600 hover:text-black duration-300'>  GROW </div><br/><br/>
<div onClick={() => toggle(rebirth[1].id)} className='border-solid border-2 p-6 border-green-600 text-centercursor-pointer  hover:bg-green-600 hover:text-black duration-300'>  BUILD </div><br/><br/>
<div onClick={() => toggle(rebirth[2].id)} className='border-solid border-2 p-6 border-green-600 text-centercursor-pointer  hover:bg-green-600 hover:text-black duration-300'>  SUSTAIN </div><br/><br/>
  </span>
  </div>
  
 <div className= {openrn === rebirth[0].id  ? "close" : "open"}>
  {rebirth[0].content}
 </div>
 <div  className= {openrn === rebirth[1].id  ? "close" : "open"}>
  {rebirth[1].content}
 </div>
 <div  className= {openrn === rebirth[2].id  ? "close" : "open"}>
  {rebirth[2].content}
 </div>
        
          
        


          
          </section>    
          <Faq/>  
</div>
    </div>
    
    )
  
}

export default Bnn
