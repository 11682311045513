import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState, useRef } from 'react';
import { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/swiper-bundle.min.css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import {BsArrowRight, BsChevronCompactLeft,BsChevronCompactRight} from 'react-icons/bs'

import '../index.css';
import one from "../images/300ppi/1.jpg"
import two from "../images/300ppi/2.jpg"
import three from "../images/300ppi/3.jpg"
import four from "../images/300ppi/4.jpg"
import five from "../images/300ppi/5.jpg"
import six from "../images/300ppi/6.jpg"
import seven from "../images/300ppi/7.jpg"
import eight from "../images/300ppi/8.jpg"
import nine from "../images/300ppi/9.jpg"
import ten from "../images/300ppi/10.jpg"
import eleven from "../images/300ppi/11.jpg"
import twelve from "../images/300ppi/12.jpg"
import thirteen from "../images/300ppi/13.jpg"
import fourteen from "../images/300ppi/14.jpg"
import fifteen from "../images/300ppi/15.jpg"
import sixteen from "../images/300ppi/16.jpg"
import seventeen from "../images/300ppi/17.jpg"
import eighteen from "../images/300ppi/18.jpg"
import AOS from 'aos'
import 'aos/dist/aos.css'



import Faq from './Faq'
import Container from 'postcss/lib/container';


const Gallery = ({ openNav, setOpenNav ,ide, setIde, open , setOpen, iden}) => {
  const [openGal, setOpenGal] = useState(false);

  useEffect(()=>{
    AOS.init({duration: 500})
 }, [])
  
   
  
  
  
  
  
 const slides =[ {id:1 , img:one},  {id:2 , img:two }, {id:3 , img:three},  {id:4 , img:four},  {id:5 , img:five} , {id:6 , img:six} , {id:7 , img:seven} ,  {id:8, img:eight },  {id:9 , img:nine},  {id:10, img:ten },  {id:11 , img:eleven} , {id:12, img: twelve},  {id:13 , img:thirteen },  {id:14 , img:fourteen } , {id:15 , img:fifteen} ,  {id:16 , img:sixteen}  ,  {id:17 , img:seventeen },  {id:18 , img:eighteen}]

 





  const closeGal = (e) => {
    if (e.target.id === "container"){
    setOpenGal(!openGal)}
    
 };


 


  return ( 
    <div className= {openNav  ? "open" : "close"}>
      <div  className= { !open ? "open" : "close"}  >
     
    <div className='w-full m-auto  relative group font-yabari  bg-green-950 overflow-hidden   '>
    <div className='absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 flex items-center justify-between'><span  className='cursor-pointer mr-2' onClick={() =>{ setOpen(!open); window.scrollTo(0,0)} } >GALLERY</span> <BsArrowRight  className=' mr-2' size={30}/><span >PARTNERSHIP</span></span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
         
         <div>
          <div  className='lg:mt-32 min-[100px]:mt-20  min-[100px]:top-32 flex flex-col items-center justify-between w-full'    >  <img  src={slides[ide].img}/> </div>     
   <div className='grid gap-4 my-2 grid-cols-2 pt-2 pb-28' data-aos="fade-up">
       <div  id={1} > <img    src={one}></img> </div>
       <div  id={2} >  <img src={two}></img> </div>
       <div  id={3}  >  <img src={three}></img> </div>
       <div  id={4}   >  <img src={four}></img> </div>
       <div id={5}   >  <img src={five}></img> </div>
       <div id={6}  >  <img src={six}></img> </div>
       <div id={7}   >  <img src={seven}></img> </div>
       <div  id={8} >  <img src={eight}></img> </div>
       
       <div id={9}  >  <img src={nine}></img> </div>
       <div id={10}  >  <img src={ten}></img> </div>

       <div id={11}   >  <img src={eleven}></img> </div>
       <div  id={12}  >  <img src={twelve}></img> </div>
    
       <div id={13}  >   <img src={thirteen}></img> </div>
       <div id={14}     >  <img src={fourteen}></img> </div>
       <div  id={15}  >  <img src={fifteen}></img> </div>
       <div id={16}   >  <img src={sixteen}></img> </div>
       <div id={17}  >  <img src={seventeen}></img> </div>
       <div id={18}  >  <img src={eighteen}></img> </div>
       </div>
      
</div>

</div>

<div >
    <Faq/>
  </div> 
</div>
</div> 
   
    
  )
}

export default Gallery
