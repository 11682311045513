import React from "react";
import { useState, useEffect } from "react";
import { Navbar, MobileNav, Typography } from "@material-tailwind/react";
import { BiMenu, BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../images/logo2.png";
import navmenu from "../images/navmenu.png";
import nvbar1 from "../images/Asset 1.svg";
import nvbar2 from "../images/Asset 2.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Header = ({ openNav, setOpenNav }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  const navList = (
    <ul
      className={`m-0 h-full text-green-600 lg:p-32  min-[100px]:p-2 min-[100px]:pt-32 font-yabari  lg:text-green-600 flex flex-col items-center gap-4   justify-items-auto delay-75 transition-all duration-1000 ${
        openNav ? "opacity-100" : "opacity-0"
      } `}
    >
      <Typography
        as="li"
        variant="small"
        className=" content-center p-2 pb-2 mb-2  font-semibold "
      >
        <Link
          onClick={() => {
            setOpenNav(!openNav);
            window.scrollTo(0, 0);
          }}
          to="/"
          className="flex  md:text-xl lg:text-[22px] hover:lg:text-3xl duration-200 items-center  min-[100px]:text-md"
        >
          HOME
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="p-2 pb-2 mb-2  font-semibold "
      >
        <Link
          onClick={() => {
            setOpenNav(!openNav);
            window.scrollTo(0, 0);
          }}
          to="/bnn"
          className="flex  md:text-xl  lg:text-[22px] hover:lg:text-3xl duration-200 items-center  min-[100px]:text-md"
        >
          BIRTHING NETBALL NIGERIA
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="p-2 pb-2 mb-2  font-semibold "
      >
        <Link
          onClick={() => {
            setOpenNav(!openNav);
            window.scrollTo(0, 0);
          }}
          to="/about"
          className="flex  md:text-xl  lg:text-[22px] hover:lg:text-3xl duration-200 items-center  min-[100px]:text-md"
        >
          ABOUT US
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="p-2 pb-2 mb-2  font-semibold "
      >
        <Link
          onClick={() => {
            setOpenNav(!openNav);
            window.scrollTo(0, 0);
          }}
          to="/registration"
          className="flex  md:text-xl  lg:text-[22px] hover:lg:text-3xl duration-200 items-center  min-[100px]:text-md"
        >
          REGISTRATION
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="p-2 pb-2 mb-2  font-semibold "
      >
        <Link
          onClick={() => {
            setOpenNav(!openNav);
            window.scrollTo(0, 0);
          }}
          to="/getinvolved"
          className="flex  md:text-xl  lg:text-[22px] hover:lg:text-3xl duration-200 items-center  min-[100px]:text-md"
        >
          GET INVOLVED
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="p-2 pb-2 mb-2  font-semibold "
      >
        <Link
          onClick={() => {
            setOpenNav(!openNav);
            window.scrollTo(0, 0);
          }}
          to="/news"
          className="flex md:text-xl  lg:text-[22px] hover:lg:text-3xl duration-200 items-center  min-[100px]:text-md"
        >
          NEWS AND UPDATES
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="p-2 pb-2 mb-2  font-semibold "
      >
        <Link
          onClick={() => {
            setOpenNav(!openNav);
            window.scrollTo(0, 0);
          }}
          to="/gallery"
          className="flex md:text-xl  lg:text-[22px] hover:lg:text-3xl duration-200 items-center  min-[100px]:text-md"
        >
          GALLERY
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="p-2 pb-2 mb-2  font-semibold "
      >
        <Link
          onClick={() => {
            setOpenNav(!openNav);
            window.scrollTo(0, 0);
          }}
          to="/reachus"
          className="flex  md:text-xl  lg:text-[22px] hover:lg:text-3xl duration-200 items-center  min-[100px]:text-md"
        >
          REACH US
        </Link>
      </Typography>
    </ul>
  );

  /* <Typography
        as="li"
        variant="small"
        className=" pt-2 mb-2 lg:text-xs min-[100px]: min-[100px]:text-md   "
      >
        <div className='group '>
        <Link  onClick={() =>{ setOpenNav(!openNav); window.scrollTo(0,0)} } to="/insights"  className="duration-1000 group-hover:bg-black p-2 px-10 md:px-5 min-[100px]:px-5 lg:bg-green-950 md:bg-black min-[100px]:bg-black text-white lg:flex lg:items-center ">
          Start a Project
        </Link>
       
        </div>
      </Typography>
      
      <div className='min-[100px]:hidden md:hidden sm:hidden lg:flex lg:text-xs flex items-center mb-6'>{navList}</div>
      */

  return (
    <div>
      <Navbar className=" absolute items-center  min-[100px]:px-10 ">
        <div className=" group fixed z-20 top-0 left-0 w-screen p-4 flex items-center justify-between text-black md:px-32  ">
          <div className="flex   items-center w-full justify-between ">
            <Link
              to="/"
              className=" z-10  min-[100px]:mx-6 cursor-pointer py-1.5 font-semibold"
            >
              <img
                className="lg:w-32  min-[100px]:w-28 flex items-center lg:absolute left-24"
                src={logo}
                loading="lazy"
                alt="logo"
              />
            </Link>

            <div className="flex items-center  z-40">
              <div
                onClick={() => {
                  setOpenNav(!openNav);
                }}
                size={30}
                className="lg:absolute right-28 flex top-6 cursor-pointer text-green-600 lg:mt-10 min-[100px]:mt-6 min-[100px]:ml-8 items-center"
              >
                <span className="lg:block min-[100px]:hidden font-thin md:text-[10px] lg:text-[10px] font-yabari tracking-[0.3em] ">
                  MENU
                </span>
                <div className=" relative group/nav flex flex-col justify-end">
                  <img
                    className="w-16 scale-75  group-hover/nav:scale-y-100 group-hover/nav:pl-5 ttransition duration-300 mt-[0.5px] "
                    src={nvbar1}
                  />
                  <img className="w-16 scale-50 mt-[1px] mx-2 " src={nvbar2} />
                </div>
              </div>
            </div>
          </div>
          <div className={scrollPosition > 0 ? "onscreen" : "offscreen"}></div>
        </div>
      </Navbar>
      <div className={openNav ? "navdown" : "navup"}>
        <div className=" ">
          <div className="flex items-center ">
            <div
              onClick={() => {
                setOpenNav(!openNav);
              }}
              className="absolute min-[100px]:right-5 lg:right-28 lg:top-4  flex min-[100px]:top-2  cursor-pointer text-green-600 lg:mt-10 min-[100px]:mt-6 min-[100px]:ml-8 items-center"
            >
              <span
                className={`lg:block min-[100px]:hidden font-thin md:text-[10px] lg:text-[10px] font-yabari tracking-[0.3em]  delay-75 transition-all duration-1000 ${
                  openNav ? "opacity-100" : "opacity-0"
                }`}
              >
                CLOSE
              </span>
              <AiOutlineClose
                size={30}
                className={`delay-75 transition-all duration-1000 ${
                  openNav ? "opacity-100" : "opacity-0"
                }`}
              />
            </div>
          </div>
          <div className="bg-white h-full">{navList}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
