import img1 from "../images/img1.jpg"
import { useState, useEffect } from 'react'
import Home from './Home'





const Body = ({ openNav, setOpenNav}) => {
 
  return (
    
    <div className= {openNav ? "open" : "close"} >
      <div>
      <div>
      <Home />
      </div>
    
      </div>
    
    </div>
  )
}

export default Body
