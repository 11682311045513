import React, { useState,useEffect } from 'react'
import imgbg from "../images/img1.jpg"
import Faq from './Faq';

import AOS from 'aos'
import 'aos/dist/aos.css'

const ReachUs = ({ openNav, setOpenNav}) => {

  useEffect(()=>{
    AOS.init({duration: 1000})
 }, [])

  const [firstName , setFirstName ] = useState('')
  const [lastName , setLastName ] = useState('')
  const [email, setEmail ] = useState('')
  const [message , setMessage ] = useState('')

  const [text, setText] =useState(0) 
  const textCounter =(e)=>{
    if(text === 600 && e.key !== 'Backspace' || text === 0 && e.key === 'Backspace'){ e.preventDefault() ; return; }
    if(e.key ===  'Backspace' ){ setText(text - 1)}else{
      setText(text + 1)
    }
  
  }
  return (
    <div>

       <div className={openNav ? "open" : "close"} >
       <div className=' h-96  w-full m-auto  relative group font-yabari  overflow-hidden  '>
           <div  style={{  backgroundImage:`url(${imgbg})` }} className=' w-full h-full bg-center   flex  items-center justify-center  bg-cover '>
           <div className='absolute bg-green-950 opacity-80 w-full p-[100%]'></div>
              <span  className='md:text-xl lg:text-4xl font-yabari text-green-600 text-center flex min-[100px]:text-2xl  flex-col items-center justify-between'>
                <h1 className='drop-shadow-2xl'>REACH US</h1>
             </span>
           </div>  
         </div> 
       <div className='   w-full m-auto  relative group font-yabari  overflow-hidden  bg-green-600'>
        <div>
        <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-950 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-950 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>REACH US</span>
          <span className=' bg-green-950  md:p-2 flex grow min-[100px]:p-1'></span></div>
        
      <section className="lg:px-16 lg:flex  lg:flex-row sm:flex-col" data-aos="fade-up">
        <div className=" container px-10 md:py-2 lg:pt-24 mx-auto mt-20 -m-2">
          <span  className="lg:text-3xl text-green-950 min-[100px]:text-2xl uppercase  ">Let’s have a chat. <br/> How can we help?</span>
        </div>
        <form className="container px-4 lg:py-24  min-[100px]:py-4 mx-auto">

          <div className="lg:w-full md:w-1/2 mx-auto">
            <div className="flex flex-col -m-2 lg:mt-12  min-[100px]:mt-4">
            <div  className="flex">
              <span className="leading-7  text-sm text-green-950">NAME </span>
              <p  className="p-2 italic  text-xs font-basc text-red-700">[Required]</p>
               </div>
              <div  className="flex -m-2 mt-4">
                
              <div className="p-2 w-1/2 ">
                <div className="relative">
                  <label for="name" className="leading-7  text-xs text-green-950">
                   FIRST
                  </label>
                  <input
                    type="text"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    id="firstname"
                    name="firstname"
                    className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-1/2 ">
                <div className="relative">
                  <label for="name" className="leading-7  text-xs text-green-950">
                   LAST
                  </label>
                  <input
                    type="text"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    id="name"
                    name="name"
                    className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
               </div>
               <div  className="flex mt-6">
              <span className="leading-7  text-sm text-green-950">EMAIL</span>
              <p  className="p-2 italic  text-xs font-basc text-red-700">[Required]</p>
               </div>
               <div  className="flex -m-2 mt-4">
              <div className="p-2 w-1/2">
             
                <div className="relative">
                  <label
                    for="email"
                    className="leading-7 text-xs text-green-950"
                  >
                   ENTER EMAIL
                  </label>
                  <input
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    className="w-full  font-basc bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white  my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    for="email"
                    className="leading-7 text-xs text-green-950"
                  >
                   CONFIRM EMAIL
                  </label>
                  <input
                    type="email"
                    required
                    value={email}
                    id="email"
                    name="email"
                    className="w-full  font-basc bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white  my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              </div>
              <div  className="flex mt-6">
              <span className="leading-7  text-sm text-green-950">MESSAGE </span>
              <p  className="p-2 italic  text-xs font-basc text-red-700">[Required]</p>
               </div>
              <div className="p-2 w-full -m-2 mt-4">
                <div className="relative">
                  <label
                    for="message"
                    className="leading-7 text-xs text-green-950"
                  >
                Please let us know what is on your mind. Have a question for us? Ask away
                  </label>
                  <textarea onKeyDown={textCounter}
                     required
                     value={message}
                     onChange={(e) => setMessage(e.target.value)}
                    id="message"
                    name="message"
                    className="w-full  font-basc bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white  text-white  h-32 text-base outline-none  my-8 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                    <p  className="p-2  text-green-950 text-xs "> {text} of 600 max characters</p>
                </div>
              </div>
              <div className="p-4 w-full mt-6">
                <button className=" text-green-950 border-green-950  border-t-2 py-2 px-0 focus:outline-none hover:bg-white  rounded text-lg">
                  Submit
                </button>
              </div>
              
              
            </div>
          </div>
        </form>
      </section>
      </div>
      <div>
          <Faq/>
          </div>
          </div>
          </div> 
    </div>
  )
}

export default ReachUs
