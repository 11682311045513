import React from 'react'
import { useState } from 'react'
import img1 from  "../images/tinc.png"
import img2 from  "../images/ace.png"
import img3 from  "../images/234.png"
import img4 from  "../images/netprotect.png"
import img5 from  "../images/nettg.png"
import img6 from  "../images/queen.png"
import img7 from  "../images/netstars.png"
import img8 from  "../images/starlets.png"
import img9 from  "../images/starlings.png"
import img10 from  "../images/starbursts.png"
import img11 from  "../images/learn.png"
import img12 from  "../images/playfees.png"
import footerbg from "../images/footerbg1.png"
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/swiper-bundle.css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import {BsArrowLeft,BsArrowRight, BsChevronCompactLeft,BsChevronCompactRight} from 'react-icons/bs'

const Programmes = () => {
  const programmes = [
    {
     programme : img1,
      name:"tinc",
       id : 1 ,  
       text: "T.I.N.C., standing for “Talent Identification, Netball Centres” is our flagship programme aimed at discovering, empowering, and equipping Junior Netball Talents (aged 11 to 14), Intermediate Netball Talents (aged 15 to 18), and Elite Netball Talents (players aged 19 and above) with skills necessary to harness netball as a positive force in their lives. It incorporates the establishment of netball centres across Nigeria.",
       topic: "How We're Finding Talents",
      },
    {
     programme : img2,
      id : 2 ,
      name:"ace",
     text:"A.C.E., standing for “Advancing Capacity Excellence” is our unique programme aimed at growing the sport of netball in Nigeria through the high-level training of coaches, technical staff, umpires, secondary school games masters, and other development staff.",
     topic: "How We're Growing Nigerian Netball",
    },
    {
     programme : img3,
     name:"234",
      id : 3 ,
      text:" “Hi, Here's Netball 234” is our special communications-driven awareness and promotion campaign aimed at popularizing netball in Nigeria. It aims at utilizing major events media coverage, social media campaigns, glamour, Nigerian-born player ambassadors, and prize incentives to engage and grow interest in the sport.",
      topic: "How We're Making Nigerian Netball Popular",
    },
    {
      programme : img4,
      name:"juniornets",
       id : 4 ,
       text:"The Junior N.E.T.S. (Netball Excellence Tournament Series) Tournament is an upcoming national tournament for the junior talents (11 to 14 years) discovered through the T.I.N.C. programme. It is designed to involve the best of the best across the nations",
       topic: "How We're Engendering Passion for Junior Talents",
     },
     {
      programme : img5,
      name:"nets",
       id : 5 ,
       text:"The N.E.T.S. (Netball Excellence Tournament Series) Tournament is an upcoming national tournament for the intermediate talents (15 to 18 years) discovered through the T.I.N.C. programme. It is designed to involve the best of the best across the nations",
       topic: "How We're Engendering Passion for Intermediate Talents",
     },
     {
      programme : img6,
      name:"nql",
       id : 6 ,
       text:"The Net Queens League is an upcoming national league for the elite talents (19 years and above) discovered through the T.I.N.C. programme. It is designed to involve the best of the best across the nations",
       topic: "How We're Engendering Passion for Elite Talents",
     },
     {
      programme : img7,
      name:"stars",
       id : 7 ,
       text:"The Nigerian Net Stars is the National Netball Senior Team of the Federal Republic of Nigeria, formed by the best of the best local and diaspora Nigerian netball talents aged 19 and above.",
       topic: "How We're Engineering National Pride Through Our Elite Talents",
     },
     {
      programme : img8,
      name:"starlets",
       id : 8 ,
       text:"The Nigerian Net Starlets is the National Netball Youth/U21 Team of the Federal Republic of Nigeria, made up of players from the senior team and the U19 team, consisting the best local and diaspora Nigerian netball talents aged 18 to 21.",
       topic: "How We're Engineering National Pride Through Our Young Elite Talents",
     },
     {
      programme : img9,
      name:"starlings",
       id : 9 ,
       text:"The Nigerian Net Starlings is the National Netball U18 Team of the Federal Republic of Nigeria, formed by the best of the best local and diaspora Nigerian netball talents aged 15 to 18.",
       topic: "How We're Engineering National Pride Through Our Intermediate Talents",
     },
     {
      programme : img10,
      name:"starbursts",
       id : 10 ,
       text:"The Nigerian Net Starbursts is the National Netball U14 Team of the Federal Republic of Nigeria, formed by the best of the best local and diaspora Nigerian netball talents aged 11 to 14.",
       topic: "How We're Engineering National Pride Through Our Junior Talents",
     },
     {
      programme : img11,
      name:"learn",
       id : 11 ,
       text:"The L.E.A.R.N. Programme is our initiative to create a social impact on junior and intermediate talents, focusing on health, digital literacy, financial literacy, education, and civic responsibility. Utilizing zone-based execution, it includes workshops and monitoring for direct and indirect impact measurement.",
       topic: "How We're Growing Well-Rounded Nigerian Netball Talents",
     },
     {
      programme : img12,
      name:"play4fees",
       id :12 ,
       text:"The Play4Fees Programme is designed to support junior and intermediate netball talents by offering scholarships for secondary school education to those who excel academically. It aims to maintain a commitment to their welfare and overall development.",
       topic: "How We're Encouraging Academic Excellence",
     },
    
    
  
  ]
  const [swiper, setSwiper] =useState(null)
  const clickNext = () =>{
    swiper.slideNext()
  }
  const clickPrev = () =>{
      swiper.slidePrev()
    
  };
  
  return (
    <div>
     
      <div className=' w-full h-full bg-white relative  font-yabari'>
        
         <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
        
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' m-2 text-green-600 lg:text-sm md:text-xs  min-[100px]:text-md'>OUR PROGRAMMES</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span>
        </div>
        
        <div className=' lg:px-[105px]  min-[100px]:p-8 lg:pt-28 lg:pb-[64px] md:pt-24  min-[100px]:py-20  '>
        <Swiper onSwiper={setSwiper} className='w-full'

      navigation={{
        prevEl: '.swiperL',
        nextEl :'.swiperR'
        
      }}
      spaceBetween={20}
      loop={true}
      centeredSlides={true}
      slidesPerView={1}
     
      modules={[Pagination, Navigation ]}
      onInit={(ev) =>{
        setSwiper(ev)  }}
        breakpoints={{
          1024:{
            slidesPerView: 3,
          },
          768:{
            slidesPerView: 1,
          },
         
         } }
     
    >
         {programmes.map((programme, index) => (
          <SwiperSlide className=' '>
           <Link to="/bnn"  className='relative flex items-center cursor-pointer justify between flex-col'>
            <div className='group h-64 w-64 [perspective:1000px]'>
              <div className='relative h-full w-full transition-all duration-500 shadow-xl [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]'>
           
           
            
            <div className='w-64 h-64 flex items-center justify-center  shadow-xl shadow-green-600'> <img className='' src={programme.programme}/> </div>
             
             <div className='absolute inset-0 h-full w-full bg-green-950/70 backdrop-blur-md p-6 font-thin text-[10px] text-center font-basc text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]'>{programme.text}</div>
          
           </div>
          </div>
           
           <div className='  flex  w-64 md:p-4  min-[100px]:p-4 items-center justify-between z-10 bg-white   text-green-600 text-xs'>
           <span  className='lg:text-sm  min-[100px]:text-xs '  >{programme.topic}</span>
           <span className='lg:text-xs  min-[100px]:text-[10px] '  >{programme.position}</span>
           </div>
          
           
             </Link>
         
         </SwiperSlide>
         ))}
         </Swiper>
         <div className='sliderController w-full top-[50%]  absolute m-auto left-0 text-green-600 flex items-center justify-between'>
      <div onClick={clickPrev} className='swiperL cursor-pointer h-full p-2 z-10 absolute top-0 bottom-0 m-auto left-0'><BsChevronCompactLeft  size={50} /></div>
       <div onClick={clickNext}  className='swiperR cursor-pointer absolute h-full p-2 z-10 right-0'><BsChevronCompactRight  size={50} /></div>
      
      
      </div>
         
         </div>
         </div> 
      
         <div>
       
         </div>
         
    </div>
  )
}

export default Programmes