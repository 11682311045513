import "./assets/tailwind.css";
import Header from "./components/Header";
import Body from "./components/Body";
import { useState, useEffect } from "react";
import Footer from "./components/Footer";
import { Router, Route, Routes, HashRouter } from "react-router-dom";
import AboutUs from "./components/AboutUs";
import Registration from "./components/Registration";
import UpMatch from "./components/UpMatch";
import GallerySlide from "./components/GallerySlide";
import ReachUs from "./components/ReachUs";
import Activities from "./components/Activities";
import GetInvolved from "./components/Involved";
import Bnn from "./components/Bnn";
import News from "./components/News";
import Gallery from "./components/Gallery";
import Resources from "./components/Resources";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import SiteMap from "./components/SiteMap";
import ActionShotsii from "./components/Gallery/ActionShots/As3";
import ActionShots from "./components/Gallery/ActionShots/As1";
import ActionShotsm from "./components/Gallery/ActionShots/As4";
import Partnership from "./components/Gallery/Partnership/Ps1";
import Partnershipm from "./components/Gallery/Partnership/Ps2";
import Partnershipii from "./components/Gallery/Partnership/Ps3";
import TeamPhotos from "./components//Gallery/TeamPhotos/Tp1";
import TeamPhotosm from "./components/Gallery/TeamPhotos/Tp2";
import Teamphotosii from "./components/Gallery/TeamPhotos/Tp3";

function App() {
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);
  return (
    <div className="App body-font tracking-widest ">
      <Header openNav={openNav} setOpenNav={setOpenNav} />

      <Routes>
        <Route
          path="/"
          element={<Body openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/registration"
          element={<Registration openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/"
          element={<Body openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/about"
          element={<AboutUs openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/news"
          element={<News openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/gallery"
          element={<GallerySlide openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/gallery/album"
          element={<Gallery openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/reachus"
          element={<ReachUs openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/activities"
          element={<Activities openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/getinvolved"
          element={<GetInvolved openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/resource"
          element={<Resources openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/bnn"
          element={<Bnn openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/termsofuse"
          element={<Terms openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/privacypolicy"
          element={<Privacy openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/sitemap"
          element={<SiteMap openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/gallery/actionshots1"
          element={<ActionShots openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/gallery/actionshots2"
          element={<ActionShotsm openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/gallery/actionshots3"
          element={<ActionShotsii openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/gallery/partnership1"
          element={<Partnership openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/gallery/partnership2"
          element={<Partnershipm openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/gallery/partnership3"
          element={<Partnershipii openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/gallery/teamphotos1"
          element={<TeamPhotos openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/gallery/teamphotos2"
          element={<TeamPhotosm openNav={openNav} setOpenNav={setOpenNav} />}
        />
        <Route
          path="/gallery/teamphotos3"
          element={<Teamphotosii openNav={openNav} setOpenNav={setOpenNav} />}
        />
      </Routes>

      <Footer openNav={openNav} setOpenNav={setOpenNav} />
    </div>
  );
}

export default App;
