import React from 'react'
import pt1 from  "../images/ace.png"
import pt2 from  "../images/tinc.png"
import pt3 from  "../images/netprotect.png"
import { useEffect, useState } from 'react';
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom';

const UpEvent = () => {
  
  useEffect(()=>{
    AOS.init({duration: 500})
 }, [])
  const games = [
    {
     img : pt3 ,
      text :   <p>Commencement of “Net, Set, Go!” </p>, 
      datePlace: "September, 2023. Abuja"
     
    
    },
    {
     img : pt1 ,
      text :  <p>Commencement of “A.C.E.” </p> , 
      datePlace : "September, 2023. Abuja, Nigeria"
    
    },
    {
     img : pt2 ,
      text :  <p>Commencement of “T.I.N.C.” </p> , 
      datePlace : " October, 2023. Abuja & Lagos, Nigeria"
    
    },
  ]
  return (
    <div>
      <div className='relative  bg-white   font-yabari'>
         <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12  bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>UPCOMING EVENTS</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
        <div className='lg:px-[105px] lg:pt-28 lg:pb-[64px] md:px-28  md:pt-24 min-[100px]:p-8 min-[100px]:py-20  grid  lg:gap-4 md:gap-16 min-[100px]:gap-16 lg:grid-cols-3 md:grid-cols-1'>
         {games.map((game) =>(
          
          
         <div className=' border-solid border-8 border-green-600  relative '>
           <div className='text-black flex flex-col justify-between gap-2 items-center ' data-aos="fade-up"> 
              <span className='flex justify-between items-center'>
               <img src={game.img} className=''/> 
             </span>
             <span className='text-green-600 lg:text-[11px] min-[100px]:text-xs text-center  '>{game.text}</span>
             <span className='text-black font-basc lg:text-[12px]  min-[100px]:text-sm z-10'>{game.datePlace}</span>
             
           </div>
           
           <Link to="/activities" className='relative left-0 m-auto right-0 top-8 flex items-center justify-center  p-6 w-52  bg-white' ><div className=' absolute  m-auto top-0  py-3 w-48 hover:w-52 text-white duration-300 font-semibold cursor-pointer bg-green-600 text-[10px] text-center '>ABOUT THIS EVENT</div></Link>
             
             </div>
         
         ))}
         </div>
         </div> 
      
         <div>
       
         </div>
    </div>
  )
}

export default UpEvent