import React, { useEffect, useState } from 'react';
import imgbg from "../images/tower.jpg"
import '../index.css';
import img1 from "../images/Dr Henry.png"
import img2 from "../images/Mrs Air Commodore Rahinatu Garba .png"
import img3 from "../images/Mrs Esther Aluko.png"
import img4 from "../images/Mrs Ifeoma Okoye.png"
import {RxCross1} from 'react-icons/rx'
import { BsArrowDownRight } from 'react-icons/bs';
import AOS from 'aos'
import 'aos/dist/aos.css'
import Faq from './Faq';
import Exec from './Exec';
import Advisor from './Advisor';






const AboutUs = ({ openNav, setOpenNav}) => {

  
 
 
  useEffect(()=>{
    AOS.init({duration: 500})
 }, [])
 
  return (
    <div className={openNav ? "open" : "close"} >
       <div>
         <div className=' h-96  w-full m-auto  relative group font-yabari  overflow-hidden  '>
          
           <div  style={{ backgroundImage:`url(${imgbg})` }} className=' w-full h-full bg-center   flex  items-center justify-center  bg-cover '>
           <div className='absolute bg-green-950 opacity-80 w-full p-[100%]'></div>
              <span  className='md:text-xl lg:text-4xl  font-yabari min-[100px]:text-2xl text-green-600 text-center flex  flex-col items-center justify-between'>
                <h1 className='drop-shadow-2xl'>ABOUT NNF</h1>
             </span>
           </div>  
         </div> 
           <div className='lg:px-[105px] lg:pb-[64px] md:px-28 bg-white text-green-600 text-center min-[100px]:p-8 min-[100px]:py-20 font-yabari flex items-center justify-center'>
             The Nigeria Netball Federation is the governing body for the sport of netball in Nigeria. Established in 2019, the NNF's mission is to develop and promote netball in Nigeria and to make it a household name in the country. The NNF strives to create a sustainable platform for the sport in Nigeria, developing and nurturing talent, and promoting social development through netball-adjacent programmes
           </div>
       <div className='   w-full m-auto  relative group font-yabari  overflow-hidden  '>
       <div className='' >
       
         <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>CORE</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
          </div>
          <div  className=' lg:pt-28 min-[100px]:pt-20 p-8  lg:p-[105px] bg-white'>
          <div className='  pb-4  'data-aos="fade-up">
            <h1 className=' text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari '>VISION</h1>
            <p className=' text-black lg:text-lg   md:text-xs min-[100px]:text-sm m-2 font-basc'>
            To be the premiere sport responsible for producing young Nigerian women with a strong sense of personal and societal discipline and self-actualization</p>
          </div>
          <div className=' 'data-aos="fade-up"  >
            <h1 className='  text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2  font-yabari '>MISSION</h1>
            <p className=' text-black lg:text-lg  md:text-xs min-[100px]:text-sm m-2 font-basc'>
            To revive, nurture, position, market, govern, and grow netball in Nigeria, through the deployment of best practices, efficient systems, durable structures, and passion-driven buy-in, thus creating a proud national netball culture.</p>
          </div>
        
          </div>
          <div className='relative  font-yabari'>
          <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 uppercase'>Strategic Ambitions</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
          <div  className=' lg:pt-28 min-[100px]:pt-20 p-8  lg:px-[105px] bg-white'>
          <div className='  pb-4 flex flex-col gap-6 text-green-600 'data-aos="fade-up">
              
           
          <h1 classname="font-yabari"> <h1 className=' text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari uppercase '>Empower</h1>
           <p className=' text-black lg:text-lg   md:text-xs min-[100px]:text-sm m-2 font-basc'>
            Empower Young Nigerian Girls with Skills to Become Instrumental Members of Society, in a Bid to Counter School Drop-Out and Unemployment Rates
          </p></h1> 
         
          <h1><h1 className=' text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari uppercase '>Build</h1>  
           <p className=' text-black lg:text-lg   md:text-xs min-[100px]:text-sm m-2 font-basc'>Build and Enhance Netball Infrastructure Across Nigeria </p></h1> 
          <h1><h1 className=' text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari uppercase '>Create</h1>  
           <p className=' text-black lg:text-lg   md:text-xs min-[100px]:text-sm m-2 font-basc'>Create Solid Grassroots to Elite Pathway for Nigerian Talents and Technicians</p></h1> 
          <h1><h1 className=' text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari uppercase '>Develop</h1>    
           <p className=' text-black lg:text-lg   md:text-xs min-[100px]:text-sm m-2 font-basc'>Develop a Reputable Netball Image in Nigeria</p></h1> 
          <h1><h1 className=' text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari uppercase '>Attain</h1>     
           <p className=' text-black lg:text-lg   md:text-xs min-[100px]:text-sm m-2 font-basc'>Podium Success and Enviable Global Ranking for Nigerian Netball</p></h1> 
          <h1><h1 className=' text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari uppercase '>Design</h1>   
          < p className=' text-black lg:text-lg   md:text-xs min-[100px]:text-sm m-2 font-basc'>Standardize and Sustain Netball Nigeria’s Operational Structures and Systems</p></h1> 
          <h1><h1 className=' text-green-600 lg:text-xl  md:text-lg min-[100px]:text-md m-2 font-yabari uppercase '>Institute</h1>    
           <p className=' text-black lg:text-lg   md:text-xs min-[100px]:text-sm m-2 font-basc'>Institute Firm and Inclusive Netball Governance and Monitoring Policies in Nigeria</p></h1> 
          
          </div>
          </div>
          </div>
          
          <div className='relative  font-yabari'>
          <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 uppercase'> Structure</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
          <div  className=' lg:pt-28 min-[100px]:pt-20 p-8  lg:px-[105px] bg-white'>
          <div className='  pb-4  'data-aos="fade-up">
          <p className=' text-black lg:text-lg   md:text-xs min-[100px]:text-sm m-2 font-basc'>
            The Nigerian Netball Federation (NNF), established in January 2023, has built a robust administrative structure to support its goals and objectives. Led by the President, the NNF's executive committee and board of trustees, along with an advisory board, have developed a 4-year strategy aimed at elevating the organization. Key roles within the NNF include the Vice President/Executive Directors for Technical, Development, and Performance, Commercial, Partnerships, and Programmes, Administration, Organization, and Competitions, Media and Digital Communications, Gender, Diversity, and Inclusivity, Social Impact and Community Development, and Finance. Each role focuses on specific aspects of the federation's operations, ranging from talent identification and development to financial management and sustainability. The NNF's advisors and trustees provide expert guidance and oversight to ensure the organization adheres to its mission and values.
          <br/> <br/>With a solid administrative structure in place, the NNF is determined to implement its ambitious 4-year strategy. The federation aims to establish a sustainable and competitive netball league in Nigeria, foster talent development, forge partnerships with key stakeholders, and generate positive social impact through netball. Supported by its advisors and trustees, the NNF aspires to become a prominent force in African netball and set an example for sports administration across the continent.
          </p>
          </div>
          </div>
          <div>
            <Exec/>
          </div>
          <div>
            <Advisor/>
          </div>
        
         <div className='relative  font-yabari'>
         <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 uppercase '>History </span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
          </div>
        <div  className=' lg:pt-28 min-[100px]:pt-20 p-8  lg:px-[105px] bg-white'>
        <div className='  pb-4  'data-aos="fade-up">
          <p className=' text-black lg:text-lg   md:text-xs min-[100px]:text-sm m-2 font-basc'>
          Netball, a popular sport for girls and young women in Nigeria during the 1950s to 1970s, experienced a decline that eventually led to its disappearance. In 1973, netball was excluded from the list of sports at the All-Africa Games, which marked the beginning of its downfall. Factors such as the lack of infrastructure, certified coaches, and recognition from national sports authorities contributed to its demise. However, in recent years, efforts have been made by a number of well-meaning organizations to revive the sport. These organizations aim to reintroduce netball as the top competitive team sport for women and young girls, providing opportunities for participation, skills development, and life lessons in teamwork and leadership.
          </p>
        </div>
       
        </div>
        <div className='relative  font-yabari'>
          <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 uppercase'>Enter the NNF</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
          <div  className=' lg:pt-28 min-[100px]:pt-20 p-8  lg:px-[105px] bg-white'>
          <div className='  pb-4  'data-aos="fade-up">
          <p className=' text-black lg:text-lg   md:text-xs min-[100px]:text-sm m-2 font-basc'>
            Today, netball in Nigeria is undergoing a government-recognized, all-encompassing rebirth. This rebirth is resultant of the recognition of the need to capitalize on the advantages of netball and build a functional sporting ecosystem that is dedicated to positively developing the country and its populace economically, socially, academically, health-wise, and career-wise, whilst significantly adding to the facets of the national that would naturally result to national pride at mention.
<br/><br/>The primary step taken to bring about this desire was the registration of a Nigerian Netball Federation (NNF) by the Corporate Affairs Commission. This step was completed in January of 2023 with the issuance with a certificate of incorporation.
<br/><br/>Subsequent to this, affiliations have begun to form between the newly created NNF and the Federal Ministry of Youth and Sports Development, who has officially recognized this NNF – headed by individuals with wealth of experience in developing sports in the nation and providing top-tier organizational leadership – as the chief body in charge of overseeing, organizing, and hosting netball and netball-adjacent activities on behalf of the nation of Nigeria.
<br/><br/>On this mandate, the Nigeria Netball Federation (NNF) has built affiliations with the Nigeria Olympic Committee (NOC), the International Olympic Committee (IOC), Africa Netball (AN) and the World Netball (World Netball).
<br/><br/>This new Nigerian Netball Federation (NNF) is a breath of fresh air in the often-turbulent world of sports federations in Nigeria. Unlike its counterparts, the NNF operates as a private sector entity under the authority of the Federal Government of Nigeria and the Nigerian Olympic Committee. The NNF enjoys complete autonomy and is self-regulating, ensuring that the buck stops with them when it comes to partnerships, external communications, liaisons, and Programme management.
<br/><br/>The NNF is a private sector autonomous organization, and is not funded by the government, nor does it report to the Nigerian Federal Ministry of Youth and Sports Development (FMYSD). This independence and its newness keep it in a position where it is not bedevilled by the history of faulty governance that has plagued many sports federations in Nigeria. Additionally, the NNF's affiliation with World Netball and Africa Netball has given it access to the best practices and resources available in the international netball community.
<br/><br/>With a determined cadre of individuals at its helm of affairs, the NNF is poised to build a stellar reputation by honouring all its contracts, agreements, and obligations.
<br/><br/>The premiere impact of this newly-formed NNF is the National Council on Sports’ approval of the inclusion of netball as a sport at the 21st National Sports Festival (NSF), Asaba 2022. To mark the event, a demonstration game was played, kicking things off into full gear.
        </p>
        </div>
        </div>
        </div>
        <div>
          <Faq/>
          </div> 
         </div>   
       </div>
       </div> 
    </div>
   
    
   
    
    
  )
  
}

export default AboutUs