import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState, useRef } from 'react';
import { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/swiper-bundle.min.css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import {BsArrowRight, BsChevronCompactLeft,BsChevronCompactRight} from 'react-icons/bs'

import '../../../index.css';

import thirteen from "../../../images/300ppi/13.jpg"
import fourteen from "../../../images/300ppi/14.jpg"
import fifteen from "../../../images/300ppi/15.jpg"
import sixteen from "../../../images/300ppi/16.jpg"
import seventeen from "../../../images/300ppi/17.jpg"
import eighteen from "../../../images/300ppi/18.jpg"
import AOS from 'aos'
import 'aos/dist/aos.css'



import Faq from '../../Faq'
import Container from 'postcss/lib/container';


const Partnershipii = ({ openNav, setOpenNav , open , setOpen, iden}) => {
  

  useEffect(()=>{
    AOS.init({duration: 500})
 }, [])
  
   
  
  
  
  
  
 const slides =[ {id:1 , img:thirteen },  {id:2 , img:fourteen } , {id:3 , img:fifteen} ,  {id:4 , img:sixteen}  ,  {id:5 , img:seventeen },  {id:6 , img:eighteen}]

 





 const [openGal, setOpenGal] = useState(false);
 const [ide , setIde] = useState(0)
 const [searchTerm , setSearchTerm] = useState(0)

 const closeGal = (e) => {
   if (e.target.id === "container"){
   setOpenGal(!openGal)}
   
};

const handleClose = (e, ) =>{
 const insp = e.target.id

 const inspo = insp-1
 setIde(inspo)
 setOpenGal(!openGal)
 console.log(ide)
 
}


 


  return ( 
    <div>
    <div   className= {openNav ? "open" : "close"}>
    <div className=' h-96  w-full m-auto  relative group font-yabari  overflow-hidden  '>
      <div  style={{ backgroundImage:`url(${slides[0].img})` }} className=' w-full h-full bg-center   flex  items-center justify-center  bg-cover '>
      <div className='absolute bg-green-950 opacity-80 w-full p-[100%]'></div>
         <span  className='md:text-xl lg:text-4xl font-yabari text-green-600 text-center flex min-[100px]:text-2xl flex-col items-center justify-between'>
           <h1 className='drop-shadow-2xl'>ACTION SHOTS 1</h1>
        </span>
      </div>  
    </div > 
    <div className='relative '>
    <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center left-0 justify-between w-full'  >
 <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
 <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 font-yabari'>ALBUM</span>
 <span className=' bg-green-600  md:p-2 flex grow  min-[100px]:p-1 '></span>
</div> 

< div className= {openGal ? "block " : "hidden "}>
      <div  id="container" onClick={closeGal} className=' fixed grid  place-items-center  bg-green-950/40 h-screen top-0 left-0 w-screen z-40 backdrop-blur-md  '>
      <div className=' font-basc  overflow-y-auto lg:w-[70%] min-[100px]:w-96 overflow-x-hidden '>
        <div   onClick={() =>{  setOpenGal(!openGal)}}>  </div>
            <img className='lg:w-full min-[100px]:w-96' src={slides[ide].img}/>  
            </div>   </div>
</div>  
        
   <div className='relative grid gap-4 my-2 grid-cols-2 pt-2 pb-28 top-24' data-aos="fade-up">
   {slides.map((slide, index) =>(
        <div>
         
         <img id={slide.id} onClick={handleClose}  src={slide.img}/>
         </div>  
         ))}
       </div>
      
</div>

</div>

<div >
    <Faq/>
  </div> 
</div>
   
    
  )
}

export default Partnershipii
