import React, { useEffect } from 'react'
import { useState, useRef } from 'react';

import Slider from "react-slick";


import {BsArrowLeft,BsArrowRight, BsChevronCompactLeft,BsChevronCompactRight} from 'react-icons/bs'
import imgbg from "../images/Deck3.jpg"
import '../index.css';
import one from "../images/300ppi/1.jpg"
import two from "../images/300ppi/2.jpg"
import three from "../images/300ppi/3.jpg"
import four from "../images/300ppi/4.jpg"
import five from "../images/300ppi/5.jpg"
import six from "../images/300ppi/6.jpg"
import seven from "../images/300ppi/7.jpg"
import eight from "../images/300ppi/8.jpg"
import nine from "../images/300ppi/9.jpg"
import ten from "../images/300ppi/10.jpg"
import eleven from "../images/300ppi/11.jpg"
import twelve from "../images/300ppi/12.jpg"
import thirteen from "../images/300ppi/13.jpg"
import fourteen from "../images/300ppi/14.jpg"
import fifteen from "../images/300ppi/15.jpg"
import sixteen from "../images/300ppi/16.jpg"
import seventeen from "../images/300ppi/17.jpg"
import eighteen from "../images/300ppi/18.jpg"
import onet from "../images/300ppi/19.jpg"
import twot from "../images/300ppi/20.jpg"
import threet from "../images/300ppi/21.jpg"
import fourt from "../images/300ppi/22.jpg"
import fivet from "../images/300ppi/23.jpg"
import sixt from "../images/300ppi/24.jpg"
import sevent from "../images/300ppi/25.jpg"
import eightt from "../images/300ppi/26.jpg"
import ninet from "../images/300ppi/27.jpg"
import tent from "../images/300ppi/28.jpg"
import elevent from "../images/300ppi/29.jpg"
import twelvet from "../images/300ppi/30.jpg"
import thirteent from "../images/300ppi/31.jpg"
import fourteent from "../images/300ppi/32.jpg"
import fifteent from "../images/300ppi/33.jpg"
import sixteent from "../images/300ppi/34.jpg"
import seventeent from "../images/300ppi/35.jpg"
import eighteent from "../images/300ppi/36.jpg"
import onea from "../images/300ppi/37.jpg"
import twoa from "../images/300ppi/38.jpg"
import threea from "../images/300ppi/39.jpg"
import foura from "../images/300ppi/40.jpg"
import fivea from "../images/300ppi/41.jpg"
import sixa from "../images/300ppi/42.jpg"
import sevena from "../images/300ppi/43.jpg"
import eighta from "../images/300ppi/44.jpg"
import ninea from "../images/300ppi/45.jpg"
import tena from "../images/300ppi/46.jpg"
import elevena from "../images/300ppi/47.jpg"
import twelvea from "../images/300ppi/48.jpg"
import thirteena from "../images/300ppi/49.jpg"
import fourteena from "../images/300ppi/50.jpg"
import fifteena from "../images/300ppi/51.jpg"
import sixteena from "../images/300ppi/52.jpg"
import seventeena from "../images/300ppi/53.jpg"
import eighteena from "../images/300ppi/54.jpg"


import Faq from './Faq'
import Container from 'postcss/lib/container';
import Gallery from './Gallery';
import { Link } from 'react-router-dom';
import ActionShots from './ActionShots';
import TeamPhotos from './TeamPhotos';


const GallerySlide = ({ openNav, setOpenNav}) => {
 const [ide , setIde] = useState(0) 
 const [open, setOpen] = useState(false);
 const [opena, setOpena] = useState(false);
 const [opent, setOpent] = useState(false);

 
  
  const inde = (e) => {
    const idep = e.target.id
    const ident = idep - 1
    setIde(ident)
    
    setOpen(!open); window.scrollTo(0,0)
    
 
  }
  const indea= (e) => {
    const idep = e.target.id
    const ident = idep - 1
    setIde(ident)
    
    window.scrollTo(0,0)
   
 
  }
  const indet = (e) => {
    const idep = e.target.id
    const ident = idep - 1
    setIde(ident)
    
    setOpent(!opent); window.scrollTo(0,0)
    
 
  }
 
  console.log(ide)

  const settings = {
    className: "center",
    prevArrow: (
      <div>
        <div className='text-green-600 relative flex items-center lg:block right-12 min-[100px]:hidden '>
        <BsChevronCompactLeft size={70}  className=' absolute' /> </div>?
    
      </div>
    ),
        
      /*<div className=' slide-prev relative flex items-center right-20 justify-center text-black p-12 bg-green-600 rounded-full' >
         <BsArrowLeft  className=' absolute' size={30}/>
      </div> */
    nextArrow: (
      <div>
         <div>
        <div className='text-green-600 relative flex items-center left-1   md:block min-[100px]:hidden'>
        <BsChevronCompactRight size={70}  className=' absolute'  /> </div>?
    
      </div>
     
    </div>
    ),
    /*  <div className=' slide-next relative flex items-center left-1 justify-center text-black p-12 bg-green-600 rounded-full'>
      <BsArrowRight className='absolute '  size={30}/> 
    </div>*/
    centerMode:true,
    dots: false,
    infinite: true,
    centerPadding: "60px",
    speed: 500,
    
    slidesToShow: 1 ,
    slidesToScroll: 1,
 
    responsive: [

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };




 const slides =[ {id:1 , img:one  , link:"/gallery/partnership1"} , {id:7 , img:seven, link:"/gallery/partnership2"}  ,  {id:13 , img:thirteen , link:"/gallery/partnership3"}]
 const slidest =[ {id:1 , img:onet, link:"/gallery/teamphotos1"}, {id:7 , img:sevent, link:"/gallery/teamphotos2"} ,  {id:13 , img:thirteent , link:"/gallery/teamphotos3"}]
 const slidesa =[ {id:1 , img:onea, link:"/gallery/actionshots1"},   {id:7 , img:sevena , link:"/gallery/actionshots2"} ,   {id:13 , img:thirteena, link:"/gallery/actionshots3" },  ]


  return (
    <div className= {openNav ? "open" : "close"}>
        <div className=' h-96  w-full m-auto  relative group font-yabari  overflow-hidden  '>
           <div  style={{ backgroundImage:`url(${imgbg})` }} className=' w-full h-full bg-center   flex  items-center justify-center  bg-cover '>
           <div className='absolute bg-green-950 opacity-80 w-full p-[100%]'></div>
              <span  className='md:text-xl lg:text-4xl font-yabari text-green-600 text-center flex min-[100px]:text-2xl flex-col items-center justify-between'>
                <h1 className='drop-shadow-2xl'>GALLERY</h1>
             </span>
           </div>  
         </div> 
      
      <div className= {open ? "open" : "close"}>
      <div className='w-full m-auto  relative group font-yabari  overflow-hidden bg-white  '>
      <div className='absolute z-10 lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
         <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 font-yabariflex items-center justify-between uppercase'> Events and Activities</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
          
      
      
      </div>
      
      <div className='bg-green-600 p-0 '>
      <div  id="container" className='bg-white h-full lg:p-40 min-[100px]:p-0  w-full backdrop-blur-md relative '> 
     
      
<div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center left-0 justify-between w-full'  >
 <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
 <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 font-yabari'> ACTION SHOTS</span>
 <span className=' bg-green-600  md:p-2 flex grow  min-[100px]:p-1 '></span>
</div> 
      <Slider {...settings}>
 
      {slidesa.map((slide, index) =>(
        <div>
         
         <div  className='slid relative items-center pt-24 justify-center  overflow-hidden cursor-pointer '><Link to={slide.link}> <img  id={slide.id} onClick={ indea} src={slide.img}/></Link ></div>
         </div>  
         ))}
      </Slider>
      </div>
     
     
      <div  id="container" className='bg-white h-full lg:p-40 min-[100px]:p-0  w-full backdrop-blur-md relative '> 
      
      <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 left-0 flex items-center justify-between w-full'  >
 <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
 <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 font-yabari'> TEAM PHOTOS</span>
 <span className=' bg-green-600  md:p-2 flex grow  min-[100px]:p-1 '></span>
</div> 
      <Slider {...settings}>
 
      {slidest.map((slide, index) =>(
        <div>
         
         <div onClick={() =>{} }  className='slid relative items-center pt-24 justify-center  overflow-hidden cursor-pointer '><Link to={slide.link}> <img  id={slide.id} onClick={ indet} src={slide.img}/></Link ></div >
         </div>  
         ))}
      </Slider>
     
      </div>
      <div  id="container" className='bg-white h-full lg:p-40 min-[100px]:p-0  w-full backdrop-blur-md relative '> 

      <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 left-0 flex items-center justify-between w-full'  >
 <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
 <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 font-yabari'> PARTNERSHIP</span>
 <span className=' bg-green-600  md:p-2 flex grow  min-[100px]:p-1 '></span>
</div> 
      <Slider {...settings}>
 
      {slides.map((slide, index) =>(
        <div>
         
         <div onClick={() =>{} }  className='slid relative items-center pt-24 justify-center  overflow-hidden cursor-pointer '><Link to={slide.link}> <img  id={slide.id} onClick={ inde} src={slide.img}/></Link ></div >
         </div>  
         ))}


      </Slider>
     
      </div>
      </div>
     
<div >
    <Faq/>
  </div> 
</div>
 </div>
    
  )
}

export default GallerySlide
