import React, { useState,useEffect } from 'react'
import imgbg from "../images/19.jpg"
import Faq from './Faq';

import AOS from 'aos'
import 'aos/dist/aos.css'
import { Select } from '@material-tailwind/react';

const GetInvolved = ({ openNav, setOpenNav}) => {

  useEffect(()=>{
    AOS.init({duration: 1000})
 }, [])

  const [firstName , setFirstName ] = useState('')
  const [lastName , setLastName ] = useState('')
  const [email, setEmail ] = useState('')
  const [message , setMessage ] = useState('')
  const [number , setNumber ] = useState('')
  const [open, setOpen] = React.useState(null);
  const toggle = id => setOpen(open === id ? undefined : id)

  const [text, setText] =useState(0) 
  const textCounter =(e)=>{
    if(text === 300 && e.key !== 'Backspace' || text === 0 && e.key === 'Backspace'){ e.preventDefault() ; return; }
    if(e.key ===  'Backspace' ){ setText(text - 1)}else{
      setText(text + 1)
    }
  
  }

const forms =[
  {
    id:1,
    answer:"I want to learn",
    viii:"Do You Have Previous Experience with Netball?",
    ix:"How would you prefer to learn netball? ",
    x:<div className="p-2 "><div className="relative"> <label for="name" className="leading-7  text-xs text-green-950"> Highest Education Level
        </label>
        <select
          type="text"
          required
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          id="name"
          name="name"
          className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-green-950 my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
      >
          < option>Primary</option>
       < option>Secondary</option>
       < option>Tertiary</option>
      </select>
      </div>
      </div>
  
   
  },
  {
    id:2,
    answer:"I want to play",
    viii:"Do You Have Previous Experience Playing Netball?",
    ix:"How would you prefer to play netball now? ",
    x:<div className="p-2 "><div className="relative"> <label for="name" className="leading-7  text-xs text-green-950"> Highest Education Level
    </label>
    <select
      type="text"
      required
      value={lastName}
      onChange={(e) => setLastName(e.target.value)}
      id="name"
      name="name"
      className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-green-950 my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
  > < option>Primary</option>
  < option>Secondary</option>
  < option>Tertiary</option>
  </select>
  </div>
  </div>,
  },
  {
    id:3,
    answer:"I want to coach",
    viii:"Do You Have Previous Experience Coaching Netball?",
    ix:"How would you prefer to coach netball now? "
  },
  {
    id:4,
    answer:"I want to referee",
    viii:".Do You Have Previous Experience Refereeing Netball?",
    ix:"How would you prefer to referee netball now? "
  },
  {
    id:5,
    answer:"I want to scout talent",
    viii:"Do You Have Previous Experience Scouting Netball Talent?",
    ix:"How would you prefer to scout netball talent now? "
  },
  {
    id:6,
    answer:"I want to support with money",
    viii:"Do You Have Previous Experience Supporting Sports Causes / Organizations?",
    ix:".How would you prefer to support the NNF now? ",
    xi:<div className="p-2 "><div className="relative"> <label for="name" className="leading-7  text-xs text-green-950"> Are you representing any organization in an official capacity by filling this form?
    </label>
    <select
      type="text"
      required
      value={lastName}
      onChange={(e) => setLastName(e.target.value)}
      id="name"
      name="name"
      className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-green-950 my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
  >
      < option>Yes</option>
   < option>no</option>
  </select>
  </div>
  </div>,

xii:  <div className="p-2 ">
<div className="relative">
  <label for="name" className="leading-7  text-xs text-green-950">
  If yes, what is the name of this organization?
  </label>
  <input
    type="text"
    required
    value={lastName}
    onChange={(e) => setLastName(e.target.value)}
    id="name"
    name="name"
    className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
  />
</div>
</div>,
xii: <div className="p-2 ">
<div className="relative">
  <label for="name" className="leading-7  text-xs text-green-950">
  Organization address?
  </label>
  <input
    type="text"
    required
    value={lastName}
    onChange={(e) => setLastName(e.target.value)}
    id="name"
    name="name"
    className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
  />
</div>
</div>,
x:<div>  <div  className="flex mt-6">
<span className="leading-7  text-sm text-green-950">ORGANIZATION CONTACT</span>
<p  className="p-2 italic  text-xs font-basc text-red-700">[Required]</p>
 </div>
 <div  className="flex -m-2 mt-4">
<div className="p-2 w-1/2">

  <div className="relative">
    <label
      for="email"
      className="leading-7 text-xs text-green-950"
    >
     ENTER EMAIL
    </label>
    <input
      type="email"
      required
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      id="email"
      name="email"
      className="w-full  font-basc bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white  my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
    />
  </div>
</div>
<div className="p-2 w-1/2">
  <div className="relative">
    <label
      for="email"
      className="leading-7 text-xs text-green-950"
    >
     PHONE NUMBER
    </label>
    <input
      type="number"
      required
      value={number}
      id="number"
      name="number"
      className="w-full  font-basc bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white  my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
    />
  </div>
</div>
</div>
</div>

    
  },
  {
    id:7,
    answer:"I want to support with services",
    viii:"Do You Have Previous Experience Supporting Sports Causes / Organizations?",
    ix:"How would you prefer to support the NNF now? ",
    xi:<div className="p-2 "><div className="relative"> <label for="name" className="leading-7  text-xs text-green-950"> Are you representing any organization in an official capacity by filling this form?
    </label>
    <select
      type="text"
      required
      value={lastName}
      onChange={(e) => setLastName(e.target.value)}
      id="name"
      name="name"
      className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-green-950 my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
  >
      < option>Yes</option>
   < option>no</option>
  </select>
  </div>
  </div>,
    xii:  <div className="p-2 ">
    <div className="relative">
      <label for="name" className="leading-7  text-xs text-green-950">
      If yes, what is the name of this organization?
      </label>
      <input
        type="text"
        required
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        id="name"
        name="name"
        className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
      />
    </div>
    </div>,
    xiii: <div className="p-2 ">
    <div className="relative">
      <label for="name" className="leading-7  text-xs text-green-950">
      Organization address?
      </label>
      <input
        type="text"
        required
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        id="name"
        name="name"
        className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
      />
    </div>
    </div>,
    x:<div>  <div  className="flex mt-6">
    <span className="leading-7  text-sm text-green-950"> ORGANIZATION CONTACT</span>
    <p  className="p-2 italic  text-xs font-basc text-red-700">[Required]</p>
     </div>
     <div  className="flex -m-2 mt-4">
    <div className="p-2 w-1/2">
   
      <div className="relative">
        <label
          for="email"
          className="leading-7 text-xs text-green-950"
        >
         ENTER EMAIL
        </label>
        <input
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          id="email"
          name="email"
          className="w-full  font-basc bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white  my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
    </div>
    <div className="p-2 w-1/2">
      <div className="relative">
        <label
          for="email"
          className="leading-7 text-xs text-green-950"
        >
         PHONE NUMBER
        </label>
        <input
          type="number"
          required
          value={number}
          id="number"
          name="number"
          className="w-full  font-basc bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white  my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
    </div>
    </div>
    </div>
  
  
  },
 
  

]
  return (
    <div>

       <div className={openNav ? "open" : "close"} >
       <div className=' h-96  w-full m-auto  relative group font-yabari  overflow-hidden  '>
           <div  style={{  backgroundImage:`url(${imgbg})` }} className=' w-full h-full bg-center   flex  items-center justify-center  bg-cover '>
           <div className='absolute bg-green-950 opacity-80 w-full p-[100%]'></div>
              <span  className='md:text-xl lg:text-4xl font-yabari text-green-600 text-center min-[100px]:text-2xl flex  flex-col items-center uppercase justify-between'>
                <h1 className='drop-shadow-2xl'>Get Involved</h1>
             </span>
           </div>  
         </div> 
       <div className='   w-full m-auto  relative group font-yabari  overflow-hidden  bg-green-600'>
        <div>
        <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-950 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-950 lg:text-sm  md:text-xs min-[100px]:text-md m-2 uppercase '>Get Involved</span>
          <span className=' bg-green-950  md:p-2 flex grow min-[100px]:p-1'></span></div>


      <section className="lg:px-16 lg:flex sm:flex-col" data-aos="fade-up">
        <div className=" container px-10 md:py-2 lg:pt-24 mx-auto mt-20 -m-2">
          <span  className="lg:text-3xl text-green-950 min-[100px]:text-2xl uppercase ">How Do You Want to Participate?</span>
        </div>
        <div>
        {forms.map((form, index ) =>(
       < section  className="lg:px-16 pb-20 lg:flex lg:flex-row sm:flex-col" data-aos="fade-up">
        <div  onClick={() => toggle(form.id)}  className=" container px-10 md:py-2 lg:pt-10  mx-auto mt-20 -m-2">
          <p className="lg:text-2xl text-green-950 min-[100px]:text-lg   cursor-pointer border-solid border-2 border-green-950 hover:bg-green-950 hover:text-green-600 p-4  ">{form.answer}</p>
        </div>
        <div className= "border-solid overflow-hidden min-[100px]:p-4  "  >
        <form className={open === form.id  ? "form_show" : "form_noshow"}>

          <div className="lg:w-full md:w-1/2 mx-auto">
            <div className="flex flex-col -m-2 lg:mt-12  min-[100px]:mt-4">
            <div  className="flex">
              <span className="leading-7  text-sm text-green-950">NAME </span>
              <p  className="p-2 italic  text-xs font-basc text-red-700">[Required]</p>
               </div>
              <div  className="flex -m-2 mt-4">
                
              <div className="p-2 w-1/2 ">
                <div className="relative">
                  <label for="name" className="leading-7  text-xs text-green-950">
                   FIRST
                  </label>
                  <input
                    type="text"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    id="firstname"
                    name="firstname"
                    className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-1/2 ">
                <div className="relative">
                  <label for="name" className="leading-7  text-xs text-green-950">
                   LAST
                  </label>
                  <input
                    type="text"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    id="name"
                    name="name"
                    className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
               </div>
               <div  className="flex mt-6">
              <span className="leading-7  text-sm text-green-950">CONTACT</span>
              <p  className="p-2 italic  text-xs font-basc text-red-700">[Required]</p>
               </div>
               <div  className="flex -m-2 mt-4">
              <div className="p-2 w-1/2">
             
                <div className="relative">
                  <label
                    for="email"
                    className="leading-7 text-xs text-green-950"
                  >
                   ENTER EMAIL
                  </label>
                  <input
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    className="w-full  font-basc bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white  my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              <div className="p-2 w-1/2">
                <div className="relative">
                  <label
                    for="email"
                    className="leading-7 text-xs text-green-950"
                  >
                   PHONE NUMBER
                  </label>
                  <input
                    type="number"
                    required
                    value={number}
                    id="number"
                    name="number"
                    className="w-full  font-basc bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white  my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
              </div>
              </div>
              <div className="p-2 ">
                <div className="relative">
                  <label for="name" className="leading-7  text-xs text-green-950">
                   RESIDENTIAL ADDRESS
                  </label>
                  <input
                    type="text"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    id="name"
                    name="name"
                    className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-white my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                </div>
              <div>
              <div className="p-2 ">
                <div className="relative">
                  <label for="name" className="leading-7  text-xs text-green-950">
                   NATIONALITY
                  </label>
                  <select
                    type="text"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    id="name"
                    name="name"
                    className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-green-950 my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
                >
                    < option>Nigerian</option>
                 < option>Not Nigerian</option>
                </select>
                </div>
                </div>
              </div>
              <div>
                {form.xi}
                </div>
                <div>
                {form.xii}
                </div>
                <div>
                {form.xiii}
                </div>
                <div>
                {form.x}
                </div>
              <div>
              
              <div className="p-2 ">
                <div className="relative">
                  <label for="name" className="leading-7  text-xs text-green-950">
                  {form.viii}
                  </label>
                  <select
                    type="text"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    id="name"
                    name="name"
                    placeholder='Select'
                    className="w-full font-basc  bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white text-base outline-none text-green-950 my-8 px-3 leading-8 transition-colors duration-200 ease-in-out"
                >
                 < option>Yes</option>
                 < option>No</option>
                </select>
                </div>
                </div>
              </div>
              <div  className="flex mt-6">
              <span className="leading-7  text-sm text-green-950">MESSAGE </span>
              <p  className="p-2 italic  text-xs font-basc text-red-700">[Required]</p>
               </div>
              <div className="p-2 w-full -m-2 mt-4">
                <div className="relative">
                  <label
                    for="message"
                    className="leading-7 text-xs text-green-950"
                  >
                   Describe this Experience 
                  </label>
                  <textarea onKeyDown={textCounter}
                     required
                     value={message}
                     onChange={(e) => setMessage(e.target.value)}
                    id="message"
                    name="message"
                    className="w-full  font-basc bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white  text-white  h-32 text-base outline-none  my-8 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                    <p  className="p-2  text-green-950 text-xs "> {text} of 300 max characters</p>
                </div>
              </div>
              <div className="p-2 w-full -m-2 mt-4">
                <div className="relative">
                  <label
                    for="message"
                    className="leading-7 text-xs text-green-950"
                  >
                   {form.ix}
                  </label>
                  <textarea onKeyDown={textCounter}
                     required
                     value={message}
                     onChange={(e) => setMessage(e.target.value)}
                    id="message"
                    name="message"
                    className="w-full  font-basc bg-green-950 bg-opacity-0 border-b-2 border-b-green-950 focus:border-white  text-white  h-32 text-base outline-none  my-8 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                    <p  className="p-2  text-green-950 text-xs "> {text} of 300 max characters</p>
                </div>
              </div>
              <div className="p-4 w-full mt-6">
                <button className=" text-green-950 border-green-950  border-t-2 py-2 px-0 focus:outline-none hover:bg-white  rounded text-lg">
                  Submit
                </button>
              </div>
              
              
            </div>
          </div>
        </form>
        </div>
        </section>
        ))}  </div>
      </section>
      </div>
      <div>
          <Faq/>
          </div>
          </div>
          </div> 
    </div>
  )
}

export default GetInvolved

