import { useEffect, useState } from 'react'
import '../index.css';
import img1 from "../images/img 1.png"
import img2 from "../images/img 2.png"
import img3 from "../images/img 3.png"
import imgbg from "../images/imgbg.jpg"
import React from 'react'
import { BsFillSquareFill} from 'react-icons/bs'
import { Link } from 'react-router-dom';





const ImageSlide = ({autoSlide = true , autoSlideInterval=5000 }) => {
 

  
 
 
  const slides = [
    {
      img : img1 ,
      text :  "Raising Talents, Netting Hope" ,
      cta1: "Who We Are",
      link1:"/about",
      cta2:"Follow the Journey",
       link2:"/activities",     
    },
    {
      img : img2 ,
      text : " Discover the Excitement of Netball!",
      cta1:"See Our Exciting Strides",
      link1:"/gallery",
      cta2:"Our Exciting Stories",
      link2:"/news",
    },
    {
      img : img3 ,
      text : "Unleashing the Potential of Nigerian Netball", 
      cta1:"Our Unleashing Strategy",
      link1:"/bnn",
      cta2:"Got Potential?", 
      link2:"/involved" ,
    }
  ]

  const [currentIndex, setCurrentIndex ]= useState(0)

const prevSlide = () => {
  const isFirstSlide = currentIndex === 0;
  const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1
  setCurrentIndex(newIndex);
};
const nextSlide = () => {
  const isLastSlide = currentIndex === slides.length - 1 ;
  const newIndex = isLastSlide ? 0 : currentIndex + 1
 
  setCurrentIndex(newIndex);
};

useEffect(()=>{
 
  const slideInterval =  setInterval(() => {
   setCurrentIndex(prevIndex => (prevIndex + 1) % slides.length)
 
   }, 6000);
  return () => clearInterval(slideInterval)
},[])

const goToSlide = (slideIndex ) => {
  setCurrentIndex(slideIndex)
}

  return (
    <div>

    <div className='  min-[100px]:h-[620px] md:h-[700px] lg:h-[780px] w-full m-auto  relative group font-yabari  overflow-hidden'>
       
       <div style={{ backgroundImage:`url(${imgbg})` }} className='  h-full bg-center bg-cover  duration-500'>
       <div className=''> < img src={slides[currentIndex].img }  className= ' absolute right-0 left-0 mx-auto  lg:w-[500px] md:w-[400px] sm:w-[300px] min-[100px]:scale-[1.7] min-[100px]:w-full  bottom-0 duration-500'/></div>
       <div className=  " noShadow "></div>
     <div className='absolute  right-0 left-0 mx-auto bottom-2'>
       
        <div className='  bottom-2  flex items-center   justify-center text-5xl text-white '>
         {slides.map((slide, slideIndex) =>(
          <div   className={` cursor-pointer  ${currentIndex === slideIndex ? " text-green-600" : "bg-opacity-50"} `} >
            <div className='p-2'
          key={slideIndex}
          onClick={()=> goToSlide(slideIndex)}
        >
             <BsFillSquareFill size={10} />
           
          </div>
        
          </div>
         ))}
        </div>
        <div className='lg:text-sm min-[100px]:text-[12px] min-[100px]:p-3 text-green-600 drop-shadow-lg flex flex-col text-center items-center p-4 py-0 justify-center '> 
        <p className=' uppercase'>{slides[currentIndex].text }</p>
        <span className='lg:text-xs min-[100px]:text-[10px] flex  p-4 gap-4'>
          <Link className='border-solid border-green-600 border-2 hover:bg-green-600 hover:text-black duration-300 p-2' to={slides[currentIndex].link1 }>{slides[currentIndex].cta1 }</Link>
          <Link  className='border-solid border-green-600 border-2  hover:bg-green-600 hover:text-black duration-300 p-2' to={slides[currentIndex].link2 }>{slides[currentIndex].cta2 }</Link>
        </span>
        </div> 
      
        </div>
       
       </div>
      
    </div>
    <div className='lg:px-[105px] lg:pb-[64px] md:px-28 bg-white text-green-600 text-center min-[100px]:p-8 min-[100px]:py-20 font-yabari flex items-center justify-center'>
          The Nigerian Netball Federation (NNF) has a vision to transform netball to the premiere sport responsible for producing young Nigerian women with a strong sense of personal and societal discipline and self-actualization.
        </div>
    </div>
  )
}

export default ImageSlide