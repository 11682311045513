
import React, { useEffect, useState } from 'react';

import '../index.css';
import img1 from "../images/mrscharity.jpg"
import img2 from "../images/mrsifeoma.jpg"
import img3 from "../images/mrlorenzo.jpg"
import img4 from "../images/mrsoso.jpg"
import img5 from "../images/mszainab.jpg"
import img6 from "../images/mrkweku.jpg"
import img7 from "../images/mrsamaila.png"
import {RxCross1} from 'react-icons/rx'
import { BsArrowDownRight } from 'react-icons/bs';
import AOS from 'aos'
import 'aos/dist/aos.css'



const Advisor = () => {

    const [open, setOpen] = React.useState(null);
    const toggle = id => setOpen(open === id ? undefined : id)


    useEffect(()=>{
        AOS.init({duration: 500})
     }, [])
     
    const advisors = [
        { 
            id:1,
         advisor : img1,
          name :   "Dr. Charity Usifo-Chenge", 
          position : "Advisor, Health",
        info: <p>Dr Charity Usifoh-Chenge is a Public Health specialist and social entrepreneur with over 24 years of experience in the development sector. She has a professional background in public health, health policy, health systems strengthening, and public health diplomacy, with a focus on women, young girls, and children's health. She believes that health is a development issue and recognizes the importance of sub-national engagement, data-driven planning, and multi-sectoral approaches to achieve results. She has experience in building consensus and striving for results within high-paced and complex multi-stakeholder engagement settings.

        <br/> She is currently a member of the Global Development team at the Bill and Melinda Gates Foundation and manages the Foundation's partnership with two Nigerian states. She has worked in consultative status with the Nigerian Minister of Health and led the development of performance monitoring frameworks and advocacy fact sheets in support of RMNCAH advocacy efforts. Dr Charity holds multiple degrees and certifications in public health, including a Doctoral Candidate in Public Health Leadership at the University of North Carolina at Chapel Hill.
        
       <br/>  As an Advisor (Health) for the Nigerian Netball Federation, Dr Charity brings her expertise in public health and health systems strengthening to support the health and well-being of athletes and promote public health outcomes in Nigeria.</p>
        },
        { 
            id:2,
         advisor : img2,
          name :   " Ifeoma Okoye, MCIPD", 
          position : "Advisor, Infrastructure and Facilities",
        info: <p>An effective HR professional, with extensive leadership and management expertise for over 20 years across professional services, banking, healthcare, telecommunication, technology
        and management consultancy.
        
        <br/>Ifeoma is Brains and Hammers’ Executive Director (Business Development), she is a professional with wealth of experience in start-up operations across human capital management, real estate development and management, business strategy, turnaround plans, process mapping and business re-engineering.
        
       <br/> She holds a Master of Arts degree in Human Resources Management from Middlesex University Business School (UK). She is a Chartered member of the Chartered Institute of Personnel and Development (M.C.I.P.D) and a member of the Chartered Institute of Managers.
        
        <br/>Ifeoma has a sound knowledge of real estate investment strategies and fund generation, with experience spanning developing sales, marketing communications and budget plans to implementing sound corporate governance structure for large corporates, SMEs in Nigeria and the UK.
        
       <br/> She is fully competent of managing all types/sizes of projects, managing all aspects of construction projects including, but not limited to budgeting, scheduling, submittals, change orders. She also has the ability to manage several medium and large ground-up development projects, major re-development projects and corporate interiors projects.
        
        <br/>As an Advisor for Infrastructure and Facilities for the Nigerian Netball Federation, Ifeoma's expertise in managing projects and real estate investment strategies has been valuable in ensuring the federation's infrastructure and facilities plans are up to par with the standards necessary to support the growth of netball in Nigeria.</p>
        },
        { 
            id:3,
         advisor : img3,
          name :   "Lorenzo, Omo-Aligbe", 
          position : "Advisor (Legal) ",
        info: <p>Mr. Lorenzo Omo-Aligbe is an International Attorney who represents clients in Nigeria, Europe and the United States in the areas of business development, regulatory issues, power & energy, political consulting as well as in public relations and international disputes resolution. Presently, Mr. Omo-Aligbe serves as General Counsel for two multinational corporations. He is multi-talented and multi-skilled with an analytical mind and a problem-solving mentality. 

       <br/> He also has a background as a Financial and Systems Analyst and he is a Certified Oracle Data Base Administrator.  After his call to bar in 1985 and law practice in Nigeria, he proceeded to the United Kingdom where he worked with the American International Group (AIG) as Financial Consultant (Life and Pensions) and rose to the position of Manager. 
        
        <br/>In addition to being a Barrister, he is an alumnus of South Bank University (SBU) in London.  Mr. Omo-Aligbe holds a Master of Science degree in Advanced Information Technology and is also a certified Oracle Professional Database Administrator. He is a member of several professional bodies including the Society of Black Lawyers, New York County Lawyers Association, (NYCLA), Life Insurance Association, Association of Systems Analyst and the Nigerian Bar Association.
        
       <br/> Mr. Omo-Aligbe’s legal prowess has enabled him to represent clients before Nigeria’s Supreme Court. As a result of his representation and knowledge of the energy sector, he was invited to The White House on the 8th of March 2012 as a guest of the President of the United States so as to coordinate legislative efforts in the United States Congress towards remediation of oil spills in the Niger Delta region of Nigeria.
        
       <br/> Building on his extensive legal experience and expertise in multiple areas, Mr. Omo-Aligbe has been proving to be a highly valuable Advisor (Legal) for the Nigerian Netball Federation.</p>
        },
        { 
            id:4,
         advisor : img4,
          name :   "Mrs. Oso Victoria Adefala", 
          position : "Advisor, Corporate Relations and Engagements",
        info: <p>Called to the Nigerian Bar in 1988, Victoria Osondu Adefala is a Barrister and Solicitor of the Supreme Court of Nigeria. She holds a Bachelor of Laws (LL. B) degree, and a Master of Laws (LL.M) in International Law from the University of Houston Bates Law School, Houston, Texas.

       <br/> Victoria’s career and experience has spanned the Manufacturing Sector where she spent sixteen years with the Michelin Group. She worked in diverse departments in the Company, including the Legal department at the Head Quarters in Clermont Ferrand, France. Before leaving Michelin, she attained the position of Executive Director of Michelin in Nigeria. Throughout her career at Michelin, she was responsible for Government relations and in this role, she was extensively exposed to the practice of private sector advocacy & Lobbying in France, Washington, and the European Union Head Quarters in Brussels. She actively participated in the business community of Manufacturers in Nigeria and has deep experience and knowledge of the sector.
        
        <br/>Victoria is a founding Partner of Whitgift Law; however, she took a leave of absence from the Law firm to answer to an opportunity to be the Nigerian Country President and CEO of Alstom Group (the Leading French Power and High-Speed Train Manufacturer), a role she held for 7 years. Victoria Adefala has wide experience in the Power sector as well as good knowledge of the Rail Transport business.
        <br/>Following her voluntary exit from Alstom Group she returned to Whitgift Law as Managing Partner.
        
       <br/> A Corporate governance expert with extensive local and international Boardroom experience, Victoria currently sits as an Independent Non-Executive Director on the Board of several organizations and businesses including Guaranty Trust Bank PLC; The French Nigeria Chamber of Commerce (FNCCI); Societe Francaise Orange Middle East and Africa Paris (Orange Telecom); She previously served as a Non-Executive Director on the Board of Italian oil and gas engineering Company Saipem Contracting Nigeria Limited,  Coronation Registrars Limited as well as  Interswitch Holdings Ltd, United Kingdom.
        
        <br/>Victoria Adefala is a keen Leadership Scholar and has completed Executive Leadership and Governance Programmes at the Columbia University, Graduate School of Business New York, the Stanford University, Graduate School of Business California, INSEAD Business School France, Harvard Business School, IESE Business School, Barcelona and the London Business School.
        
       <br/> As an Advisor (Corporate Relations and Engagements) for the Nigerian Netball Federation, Victoria's expertise in corporate governance, government relations, and leadership has been instrumental in the development and maintenance of strong relationships with corporate partners and stakeholders, as well as to the provision of strategic advice and guidance to the organization's leadership.</p>
        },
        { 
            id:5,
            advisor : img5,
             name :   "Ms. Zenab Mahamat Adam", 
             position : "Advisor, Education",
           info: <p>
        <br/>Zenab Adam is a dynamic and accomplished professional with a diverse background in education and communication. As an Educational Consultant, Applied Behaviour Analysis Trainer, Tedx Speaker, and Communication Coach, she has honed her expertise over the past decade.
       <br/> With a degree in Business & Management from the University of Sunderland, UK, and specialized training in Applied Behaviour Analysis from the Institute of Child Education & Psychology Europe, Zenab possesses a well-rounded skill set.
       <br/> Her passion for education and personal development led her to establish Pro Teach Consults, a company dedicated to transforming schools and empowering educators through evaluations, monitoring, and training. With a proven track record, she has successfully collaborated with prominent educational institutions like Andal Science Academy, El Buhaj Academy, IWEIng, Sunflower Schools, Sierra Preparatory School, Path To Success Int’l Academy, and others.
        <br/>Beyond her professional endeavors, Zenab is deeply committed to community development and philanthropy. She serves as an executive in three NGOs: MATA Network, Give Foundation, and The Reformation. Through these organizations, she empowers women, children, and motivates youth in community development and leadership projects, including the impressive Mai Dattako Basic School initiative, which provides quality education to underprivileged children in Gwale LGA, Kano.
        <br/>Driven, focused, and dedicated to achieving transformational results in every endeavor, Zenab Adam is a true catalyst for positive change in the educational sector and the wider community.
           </p> 
        },
        { 
            id:6,
            advisor : img6,
             name :   " Dr. Kweku Adedayo Tandoh", 
             position : "Advisor, Technicals/Medical",
           info: <p>Dr. Kweku Adedayo Tandoh is currently the CEO of Sport Connect Consultancy Ltd, a leading Sports Strategic Planning company. He was the immediate past Executive Chairman of the Lagos State Sports Commission and Chairman of its Governing Board. He was also a member of the Lagos State Sports Trust Fund. A seasoned sports administrator, Dr. Tandoh brings over 28 years of worldwide professional management expertise to the fore-front of sports in Nigeria.
          <br/> Dr. Tandoh is a trained medical doctor and has had a very versatile career, having gained several relevant qualifications that span across diverse sectors, locally and internationally. For example, he is a certified Associate Member of the Chartered Institute of Arbitrators UK and also a Fellow of the Institute of Public Administrators of Nigeria. He has also gained experience in various fields including Medicine, Marketing, ICT, Planning & Research, Tourism and Sports. 
          <br/> Over the years, Dr. Tandoh has held several positions in various capacities including but not limited to the following: Special Assistant to the Honourable Minister of Sports (2010-2011), Vice-Chairman Lagos State Badminton Association (1992-1994), Vice-Chairman Lagos State Sports Medicine Association, Vice-Chairman Lagos State Football Association (2000-2004), Director Planning, Research & Education at National Gallery of Art (2006-2013), Director of Sports Lagos State Sports Council (2013-2015), etc A trailblazer, Dr. Tandoh was Secretary-General of the Local Organizing Committee of the Eko 2012 National Sports Festival, the festival that has been labelled as the best organized to date. He was also the Consultant to the Local Organising Committee of the 20th National Sports Festival held in Benin City, Edo State in April 2021.
          <br/> In 2022, he was appointed Consultant on Sports to the Edo State Government.
          <br/> In recognition of his vast and well documented experience and contributions to sports, Dr. Tandoh was appointed a member of the Technical and Development Commission of the Nigeria Olympic Committee in 2018 and remains a member till date. He also serves in various capacities ranging from member to Chairman of different local and international bodies for special assignments in sports and other relevant strategic sectors. 
          <br/> He is currently the Chairman of the Youth Committee of the African Table Tennis Federation.
          <br/> A man with a constant passion for self-development and knowledge garnering, he has attended several courses and conferences across the globe. Among the many courses he has completed include the International Olympic Committee Advanced Sports Management Diploma Course, the International Olympic Committee Strategic Planning Facilitators’ Training, FIFA Futuro II Sports Management Course, 1st World Conference on Sports and Tourism in Barcelona, Spain (2002), Lagos Business School Turning Strategy into Action Course and the Harvard Extension School Strategic Leadership Course.  He is a member of the Ikoyi Club 1938 and is happily married with two blessed children. </p> 
        },
       /* { 
            id:7,
            advisor : img7,
             name :   " Mr. Samaila Umar Sambo", 
             position : "Advisor, Community - Northern Nigeria",
           info: <p>Samaila Umar Sambo is an accomplished professional with a diverse background and a range of skills. He was born on August 16, 1968, in Gusau, Zamfara State. He obtained a B.Sc (Hons) in Chemistry from Bayero University, Kano, in 1992, and later pursued a Postgraduate Diploma in Public Administration (PGDPA) from Ahmadu Bello University, Zaria, as well as a PGD in C.Chem from BUK. Samaila has also attended various courses and seminars to enhance his knowledge and expertise.
          <br/> Following a contract period at NNPC Kaduna Zonal Office from 1994 to 1996, he ventured into private business. Currently, Samaila holds the position of Executive Director at UMRA Group of Companies, which operates in the fields of Oil and Gas, Real Estate, and General Services. From 2019 to 2021, he served as the Executive Chairman of the Zamfara Urban and Regional Planning Board (ZUREPB).
          <br/> Samaila is known for his exceptional organizational skills and self-motivation, which enable him to consistently meet deadlines and accomplish tasks with transparency. He has undergone training in international leadership and mediation organization by the Mediation Training Institute in Dubai, UAE, among other training programs. Furthermore, he has developed hands-on skills for creative vision and optimal utilization of human and material resources.
          <br/> In addition to his professional achievements, Samaila has taken on various ad-hoc duties. He served as the Director of Gusau Micro Finance from 2008 to 2010, and he is a member of the Overseas Bayeros Association in Taipei, Taiwan, as well as the Nigerian Association of Transport Owners (NARTO). As an Executive Director at UMRA Oil and Gas, he contributes to the company's success.
          <br/> Samaila has been recognized with several awards for his contributions to society. He received the Youth Ambassador for Peace award from the Universal Peace Federation and Youth for World Peace, an NGO with consultative status at the United Nations (ECOSOC). Additionally, he was honored with the Award of Excellence by the Nigeria Youth Forum in Democracy (NYFDEM), the Role Model award by the National Union of Zamfara Students Society (NUZAMSS) BUK Chapter, and the Award of Merit by the Northern Peace Foundation (NPF).
          <br/> Outside of his professional endeavors, Samaila enjoys reading, conducting research, and traveling. He is also skilled in various sports, including football, badminton, and jogging. </p> 
        }, */
        
      ]
     
  return (
    <div>
    <div className=' bg-green-950'>
       <div className=' ' >
      

    <div className='relative  font-yabari'>
      <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
       <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
       <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>OUR ADVISORS</span>
       <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
       </div>
       <div className=' lg:px-[105px]  min-[100px]:p-8 lg:pt-28 lg:pb-[64px] md:pt-24 grid min-[100px]:py-20  lg:gap-3 md:gap-8  min-[100px]:gap-8  lg:grid-cols-3 md:grid-cols-1'  data-aos="fade-up">
      {advisors.map((advisor) => (
       <div className=' '>
        <div  onClick={() => toggle(advisor.id)} className='relative flex items-center justify between flex-col'>
        <div> <img className='w-[900px]' src={ advisor.advisor}/> </div>
        <div className='  flex lg:w-full md:w-[700px]  min-[100px]:w-full md:p-4  min-[100px]:p-2 items-center justify-between bg-green-600   text-green-950 text-xs'>
        <span  className='lg:text-[12px]  min-[100px]:text-xs uppercase '  >{advisor.name} <br/> <span className='text-sm mt-5 font-basc'>{advisor.position}</span></span>
        
       
        </div>

         <div className= "border-solid overflow-hidden font-thin"  >
         <div className= {open === advisor.id  ? "exec_show" : "exec_noshow"}><p className='bg-green-600 font-semibold font-basc text-green-950 p-3'>{advisor.info}</p></div>
        </div>
       
          </div>
      
      </div>
      ))}
      </div>
 </div>
 </div>
 </div>
  )
}

export default Advisor
