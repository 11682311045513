import React from 'react';

import '../index.css';
import img1 from "../images/img1.jpg"
import img2 from "../images/img2.jpg"
import img3 from "../images/img3.jpg"
import img4  from "../images/Deck3.jpg"
import pt1 from  "../images/crest.png"
import pt2 from  "../images/gatorade.png"
import pt3 from  "../images/garmin.png"
import pt4 from  "../images/gilbert.png"
import pt5 from  "../images/suncorp.png"
import pt6 from  "../images/telkom.png"
import pt7 from  "../images/Picture.png"
import pt8 from  "../images/hands.png"
import pt9 from  "../images/vitality.png"
import pt10 from  "../images/nike.png"
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useState, useEffect } from 'react'
import ImageSlide from './ImageSlide';
import Inspot from './Inspot';
import Socials from './Socials';
import Faq from './Faq';
import UpMatch from './UpMatch';
import UpEvent from './UpEvent';
import Programmes from './Programmes';





const Home = () => {

 
 
  return (
  <div>
     <div >
     <ImageSlide/>
       <div>
       
      </div>
      <div>
        <UpEvent/>
      </div>
      <div >
        <Programmes/>
      </div> 
      <div >
        <Inspot/>
      </div>
      <div >
        <Faq/>
      </div> 
     
      </div>
  </div>
  )
  
}



 

export default Home
