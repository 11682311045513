import React from 'react'

const SiteMap = () => {
  return (
    <div className=' h-full  w-full m-auto  relative group    bg-black text-white overflow-hidden  '>
        <div className='mt-20 p-10'>
        <div className='text-4xl py-6 uppercase'>SiteMap</div>
        <div>
            <div className='text-2xl uppercase '>Home</div>
            <div className='py-6 text-lg'>
                <h1 className='text-2xl uppercase '>Birthing Netball Nigeria</h1>
                <ul>
                <li>Empower Ambition</li>
                  <li>Stages</li>
                <li>Rebirth Ambition</li>
                <li>Grow</li>
                <li>Build</li>
                <li>Sustain</li>
                </ul>
            </div>
            <div className='py-6 text-lg'>
                <h1 className='text-2xl uppercase '>About Us</h1>
                <ul>
                <li>Core</li>
                <li>Strategic Ambitions</li>
                <li>Structure</li>
                <li>The Team</li>
                <li>Our Advisors</li>
                <li>History</li>
                <li>Enter the NFF</li>
                </ul>
            </div>
            <div className='py-6 text-lg'>
                <h1 className='text-2xl uppercase '>Get Involved</h1>
            </div>
            <div className='py-6 text-lg'>
                <h1 className='text-2xl uppercase '>News and Updates</h1>
                <ul>
                <li>In the Spotlight</li>
                <li>Local Stories</li>
                <li>International News</li>
                </ul>
            </div>
            <div className='py-6 text-lg'>
                <h1 className='text-2xl uppercase '>News and Updates</h1>
                <ul>
                <li>In the Spotlight</li>
                <li>Local Stories</li>
                <li>International News</li>
                </ul>
            </div>
            <div className='py-6 text-lg'>
                <h1 className='text-2xl uppercase '>Gallery</h1>
                <ul>
                <li>Action Shots</li>
                <li>Team Photos</li>
                <li>Partnership</li>
                </ul>
            </div>
            <div className='py-6 text-lg'>
                <h1 className='text-2xl uppercase '>Reach Us</h1>
            </div>
        </div>
        </div>
    </div>
  )
}

export default SiteMap