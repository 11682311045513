import React from 'react'
import two from "../images/two.png"
import img2 from  "../images/news2.png"
import img3 from  "../images/news3.png"
import img4 from  "../images/news4.png"
import img5 from  "../images/news5.png"
import img6 from  "../images/news6.png"
import { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useEffect} from 'react';
import AOS from 'aos'
import 'aos/dist/aos.css'

const LocalStories = () => {

  useEffect(()=>{
    AOS.init({duration: 1000})
 }, [])

  const [openGal, setOpenGal] = useState(false);
  const [ide , setIde] = useState(0)
  const [searchTerm , setSearchTerm] = useState(0)

  const closeGal = (e) => {
    if (e.target.id === "container"){
    setOpenGal(!openGal)}
    
 };

 const handleClose = (e, ) =>{
  const insp = e.target.id
  const inspo = insp-1
  setIde(inspo)
  setOpenGal(!openGal)
  console.log(ide)
}

 

  const inspots = [
    {
      id: 6,
      image : two,
      date : '12/01/2023',
       title :   "Netball Demonstration Match at the National Sports Festival, Asaba 2022, Spurs Hope for a Structured Revival of the Sport to Nigeria", 
      preview: "Asaba, Nigeria - The National Sports Festival, held in Asaba in November 2022, hosted a demonstration match of netball, which has sparked hope for a structured revival of the sport in Nigeria. The exhibition game was a showcase of the game's potential in the country, and it attracted many spectators, including key stakeholders in the Nigerian sports industry.",
      text: <div>Asaba, Nigeria - The National Sports Festival, held in Asaba in November 2022, hosted a demonstration match of netball, which has sparked hope for a structured revival of the sport in Nigeria. The exhibition game was a showcase of the game's potential in the country, and it attracted many spectators, including key stakeholders in the Nigerian sports industry.
      <br/>The netball demonstration match was held in partnership with the Nigerian Netball Federation, NNF (which was still being formed at the time), who saw that as a great opportunity to bring the game back into the spotlight. Netball, a popular sport in other Commonwealth countries, has not been widely played or supported in Nigeria for years. However, with this demonstration game, there is renewed hope that the sport can make a comeback and become a force to be reckoned with.
      <br/>The players who participated in the exhibition match showed great skill, with impressive athleticism and teamwork on display. The spectators who witnessed the game were thrilled by the performance and applauded the players for their efforts. The demonstration match also allowed key stakeholders to evaluate the sport's potential for growth and expansion in Nigeria.
      <br/>The Nigerian Netball Federation has expressed their excitement about the demonstration match's success and hopes that it will lead to more opportunities for the sport. They believe that the demonstration match was a great way to generate interest in the game and get more people involved.
      <br/>Overall, the netball demonstration match held at the National Sports Festival in Asaba 2022 has raised hopes for a structured revival of the sport in Nigeria. With the support of key stakeholders and the Nigerian Netball Federation, the sport has the potential to grow and thrive in the country once again.</div>,
              read: "READ MORE..."
    },
    {
       id: 5,
        image : img2,
        date : '13/01/2023',
         title :   "History of Netball in Nigeria: A Journey of Revival", 
        preview: " Netball, once a popular sport for girls and young women in Nigeria during the 1950s to 1970s, faced a gradual decline that eventually led to its disappearance. A significant setback occurred in 1973 when netball was excluded from the list of sports at the All-Africa Games, marking the beginning of its downfall. Several factors contributed to this decline, including the lack of proper infrastructure, certified coaches, and recognition from national sports authorities.",
        text: <div>Netball, once a popular sport for girls and young women in Nigeria during the 1950s to 1970s, faced a gradual decline that eventually led to its disappearance. A significant setback occurred in 1973 when netball was excluded from the list of sports at the All-Africa Games, marking the beginning of its downfall. Several factors contributed to this decline, including the lack of proper infrastructure, certified coaches, and recognition from national sports authorities.
       <br/> The journey of revival for netball in Nigeria took a significant step forward with the formation of the NNF. In January 2023, the NNF was officially registered with the Corporate Affairs Commission, receiving a certificate of incorporation. Subsequently, the federation established affiliations with key organizations such as the Federal Ministry of Youth and Sports Development, the Nigeria Olympic Committee (NOC), the International Olympic Committee (IOC), Africa Netball (AN), and World Netball (World Netball).
         <br/>Unlike many sports federations in Nigeria, the NNF operates as a private sector autonomous organization, enjoying complete autonomy and self-regulation. This independence and affiliation with international netball bodies provide the NNF access to best practices and resources in the global netball community.
         <br/>One notable milestone for the NNF was the National Council on Sports' approval of including netball as a sport at the 21st National Sports Festival (NSF) held in Asaba in 2022. To kick-start the event, a demonstration game was played, generating enthusiasm and excitement among participants and spectators.
         <br/>The NNF's journey of revival is a testament to the resilience and determination of all involved. With a clear vision and mission, the federation aims to establish netball as the top competitive team sport for women and young girls in Nigeria. The focus on grassroots development, talent identification, and social impact programs ensures that netball becomes a vehicle for positive change in the lives of young Nigerian women, empowering them to achieve personal and societal discipline and self-actualization. As the NNF continues its efforts, the future of netball in Nigeria holds great promise.</div>,
                read: "READ MORE..."
      },
      {
        id :4,
        image : img3,
        date : '14/01/2023',
         title :   "Understanding the Basics: Rules and Positions in Netball", 
        preview: "For newcomers to the world of netball, understanding the rules and positions is essential to fully appreciate the sport's beauty and complexity. Netball is a dynamic team game played on a rectangular court, where two teams of seven players each strive to outscore their opponents. Let's delve into the fundamental aspects of netball to grasp its essence better." ,
        text: <div>For newcomers to the world of netball, understanding the rules and positions is essential to fully appreciate the sport's beauty and complexity. Netball is a dynamic team game played on a rectangular court, where two teams of seven players each strive to outscore their opponents. Let's delve into the fundamental aspects of netball to grasp its essence better.

       <br/> The Court and Positions:
       <br/> Netball courts are divided into thirds, with shooting circles at each end. The positions on a netball team are as follows:
        <br/> <br/> 1.Goal Shooter (GS): Positioned inside the attacking shooting circle, the GS's primary role is to score goals. They must have a keen eye for accuracy and be skilled in shooting from various angles.
        <br/> <br/> 2.Goal Attack (GA): Also situated in the shooting circle, the GA combines goal-scoring with feeding the ball to teammates. They need to be agile, versatile, and possess good passing skills.
        <br/> <br/> 3.Wing Attack (WA): Positioned in the attacking third, the WA's main responsibility is to create attacking opportunities and feed the ball to the shooters. They need to be quick, agile, and have excellent court vision.
        <br/> <br/> 4.Centre (C): The pivotal position, the Centre, plays in the middle third of the court and is involved in both attack and defence. They are responsible for distributing the ball and linking the team's play.
       <br/> <br/>  5.Wing Defence (WD): Positioned in the defensive third, the WD's primary task is to block the opposing team's attacking plays and disrupt their flow. They require speed, agility, and strong defensive skills.
       <br/> <br/>  6.Goal Defence (GD): The GD's role is to defend the opposing team's Goal Attack and help prevent goals. They must be adept at intercepting passes and marking their opponent closely.
       <br/> <br/>  7.Goal Keeper (GK): Positioned inside the defensive shooting circle, the GK's main objective is to defend the opposing team's Goal Shooter and prevent them from scoring. They need to be tall, agile, and skilled in marking.
        
       <br/> Basic Rules:
       <br/> <br/>  1.Players are not allowed to move with the ball; they must pivot on one foot while passing or shooting.
       <br/> <br/>  2.When a goal is scored, the game resumes with a center pass from the team that was scored against.
       <br/> <br/>  3.Players must maintain a distance of at least three feet from the player with the ball.
       <br/> <br/>  4.The ball must be passed within three seconds of receiving it.
       <br/> <br/>  5.Contact between players is not permitted, and obstruction of opponents' movement is penalized.
       <br/> <br/>  Netball is a sport that requires precision, coordination, and quick decision-making. As the Nigerian Netball Federation works towards popularizing the sport, understanding these basics will lay a strong foundation for enthusiasts, players, and future netball stars in the country. Whether you're a player or a fan, embracing the rules and positions of netball will undoubtedly enhance your experience of this exhilarating game.</div>,
        read: "READ MORE..."
      },
      {
        id: 3,
        image : img4,
        date : '15/01/2023',
         title :   "Advantages of Netball to Nigeria: Boosting Economy and National Pride", 
        preview: "The efforts to revive netball in Nigeria stem from the understanding that the sport bears significant advantages for both the country's economy and the national pride of its people. As the Nigerian Netball Federation (NNF) takes strides to promote the sport, it envisions a future where netball becomes a catalyst for positive change in the nation.",
        text: <div>Boosting Nigeria's Economy:

       <br/> <br/> 1.Job Creation: Netball has the potential to create job opportunities in various sectors of the economy. The introduction of netball as a viable sport could lead to employment opportunities in coaching, sports management, event planning, and more. This could be instrumental in reducing Nigeria's unemployment rate, as it did in countries like Australia and New Zealand, where the netball industry employs thousands of individuals.
          <br/> <br/> 2.Tourism: Hosting netball competitions and events in Nigeria has the potential to attract tourists, thus contributing to the growth of the tourism sector. With the right infrastructure and promotion, netball can become a driving force for tourism in the country, mirroring the impact of the Netball World Cup in Liverpool, England, which generated millions in economic activity.
          <br/> <br/> 3.Sponsorship and Advertising: Netball's growing popularity can attract corporate sponsors and advertisers, providing financial support for the sport and translating into economic benefits for Nigeria. As seen in countries like South Africa, where the national netball team enjoys major sponsorships and broadcasted matches attract advertising revenue, netball can become a lucrative platform for businesses.
         <br/> <br/>  4.Youth Development: Netball can play a crucial role in youth development, providing young individuals with opportunities to develop essential skills such as teamwork, communication, and leadership. These skills can contribute to a more skilled workforce and increased entrepreneurship, bolstering the nation's economic prospects.
        
         <br/> <br/>  Fostering National Pride:
         <br/> <br/>  1.Building a Sense of Community: Netball's inclusive nature brings people together in a shared experience, fostering a sense of community and belonging. In diverse countries like Nigeria, where cultural backgrounds may differ, netball serves as a unifying force, enhancing national pride.
         <br/> <br/>  2.Promoting Gender Equality and Empowerment: Netball's popularity among women and girls promotes gender equality and female empowerment. By supporting the sport, sponsors contribute to a more inclusive and diverse society, enhancing national pride and unity.
         <br/> <br/>  3.Preserving National Identity: Each country has its unique style of play and traditions associated with netball. Supporting the sport helps preserve these traditions, strengthening the sense of national identity and pride, as evident in Jamaica and Australia, where netball is a significant source of national pride.
         <br/> <br/>  As the Nigerian Netball Federation works diligently to establish netball as a prominent sport in the country, the potential direct impacts on the economy and national pride serve as driving forces. By fostering economic growth, promoting unity, and providing opportunities for all, netball stands poised to become a sport that contributes positively to Nigeria's development and identity on the global stage.
        </div>,
                read: "READ MORE"
      },
      {
         id: 2,
          image : img5,
          date : '15/01/2023',
          title : "Building Socially-Responsible Individuals: Netball's Impact on Community and Leadership in Nigeria", 
          preview: "Netball, beyond being a sport of skill and strategy, possesses the remarkable ability to nurture socially-responsible individuals who play significant roles in their communities and demonstrate exemplary leadership qualities. As the Nigerian Netball Federation (NNF) strives to promote the sport across the nation, it recognizes the potential of netball to foster positive values and instil a sense of responsibility in its players.",
          text: <div>Netball, beyond being a sport of skill and strategy, possesses the remarkable ability to nurture socially-responsible individuals who play significant roles in their communities and demonstrate exemplary leadership qualities. As the Nigerian Netball Federation (NNF) strives to promote the sport across the nation, it recognizes the potential of netball to foster positive values and instil a sense of responsibility in its players.

         <br/><br/> 1.Encouraging Community Involvement:
          <br/><br/> Netball teams often engage in community service projects and events, fostering a strong sense of responsibility and commitment to the community. Through these initiatives, netball players learn the importance of giving back and actively contribute to the well-being of their localities. The NNF aims to encourage netball players to become community activists and advocates for positive change, promoting social responsibility beyond the confines of the court.
          
         <br/><br/>  2.Promoting Inclusivity and Diversity:
          <br/><br/> Netball is a sport that welcomes players of all backgrounds and abilities, promoting inclusivity and diversity within the sporting community. By providing a platform where individuals from various walks of life come together as teammates, netball cultivates an environment of respect and understanding. The NNF emphasizes the value of diversity, using netball as a tool to bridge gaps and build socially-responsible individuals who embrace unity and celebrate differences.
          
         <br/><br/>  3.Developing Leadership and Teamwork Skills:
         <br/><br/>  At the heart of netball lies teamwork and effective communication. Players must work harmoniously, supporting each other on the court to achieve a common goal. Through this collaborative nature of the sport, netball players develop essential leadership skills, learning to lead by example, inspire their teammates, and make collective decisions. The NNF aims to nurture these leadership qualities in its players, empowering them to become leaders not only in netball but in their communities and professions.
          
          
         <br/><br/>  4.Becoming Role Models:
         <br/><br/>  Netball provides a platform for players to serve as positive role models for young individuals, inspiring and motivating them to reach their full potential. Coaches and team captains play a crucial role in guiding young players and encouraging positive behaviours. The NNF acknowledges the significance of these role models and strives to promote a culture where netball players embrace their responsibility as inspirations for the next generation, leading with integrity and purpose.
          
         <br/><br/>  5.Contributing to Safe and Supportive Environments:
         <br/><br/>  Investing in netball contributes to the creation of safe and supportive environments for young individuals. By providing opportunities to participate in a structured and positive setting, netball helps prevent the formation of deviant behaviors and promotes the development of responsible and accountable young women. The NNF recognizes the potential of netball in creating environments where players thrive and grow into socially-responsible individuals, positively impacting their communities.
          
         <br/><br/>  Netball's influence goes beyond the boundaries of the court. As the Nigerian Netball Federation works diligently to promote the sport, it recognizes the profound impact netball can have on building socially-responsible individuals and nurturing effective leaders. By encouraging community involvement, promoting inclusivity, and emphasizing the value of teamwork and leadership, netball serves as a powerful force in shaping individuals who actively contribute to the betterment of their communities and the nation as a whole. The NNF envisions a future where netball becomes not only a sport of skill but also a catalyst for positive social change in Nigeria.</div>,
                  read: "READ MORE..."
        },
        {
          id :1,
          image : img6,
          date : '17/01/2023',
           title :  " Countdown to Glory: The Upcoming Netball World Cup in South Africa", 
          preview: " The excitement is building as netball enthusiasts from around the world eagerly anticipate the highly anticipated Netball World Cup set to take place in South Africa from 28 July 2023 to 6 August 2023. The event promises to be a celebration of athleticism, teamwork, and sportsmanship, bringing together some of the most skilled netball players from different nations. As the host country, South Africa is ready to showcase its warm hospitality and passion for the sport on the global stage." ,
          text: <div>The excitement is building as netball enthusiasts from around the world eagerly anticipate the highly anticipated Netball World Cup set to take place in South Africa from 28 July 2023 to 6 August 2023. The event promises to be a celebration of athleticism, teamwork, and sportsmanship, bringing together some of the most skilled netball players from different nations. As the host country, South Africa is ready to showcase its warm hospitality and passion for the sport on the global stage.

        <br/> <br/>  1.The Road to the Netball World Cup:
         <br/> <br/>  Preparations for the Netball World Cup have been underway for months, as teams fine-tune their strategies, hone their skills, and undergo rigorous training sessions. National squads from countries like Australia, New Zealand, England, Jamaica, and South Africa have been actively competing in regional tournaments and friendly matches to gain momentum and form. This World Cup will see fierce competition as the world's best netball teams battle it out for the prestigious title.
          
         <br/> <br/>  2.South Africa's Moment to Shine:
         <br/> <br/>  The Netball World Cup will be a significant milestone for South Africa, as they host the event for the first time. This presents an exceptional opportunity for the country to showcase its passion for netball, promote tourism, and highlight the progress made in the sport. South Africa's national team, known as the Proteas, has shown tremendous growth in recent years and aims to make a strong statement on home soil. The nation's netball fans eagerly await the chance to support their team and witness netball history in the making.
          
        <br/> <br/>   3.Uniting the World Through Netball:
        <br/> <br/>   The Netball World Cup transcends geographical boundaries, uniting nations from different continents and cultures. The event creates a unique atmosphere of camaraderie and friendship among fans, players, and officials. Supporters from diverse backgrounds come together, sharing their love for netball and celebrating the passion and dedication of the athletes. The World Cup serves as a symbol of global unity and sportsmanship, reinforcing the positive values that netball embodies.
          
          
          
        <br/> <br/>   4.Empowering Women and Girls:
       <br/> <br/>    Netball has long been a sport that empowers women and girls, providing them with a platform to showcase their athleticism and leadership skills. The Netball World Cup further amplifies this message, inspiring young girls to dream big and pursue their passions fearlessly. The tournament serves as a beacon of inspiration, demonstrating that with determination and hard work, women can excel in any field, be it sports, academics, or their careers.
          
        <br/> <br/>   5.A Thrilling Spectacle:
        <br/> <br/>   The Netball World Cup promises to deliver thrilling and nail-biting matches that will keep fans on the edge of their seats. From lightning-fast passes to precision shooting and strategic defensive maneuvers, each game will be a display of athleticism and sportsmanship. With every goal scored and every defensive intercept, the passion and energy of the players will reverberate across the courts, making for a truly unforgettable sporting experience.
          
        <br/> <br/>   6.Leaving a Lasting Legacy:
       <br/> <br/>    Beyond the thrill of the competition, the Netball World Cup aims to leave a lasting legacy in South Africa and beyond. The tournament's impact extends beyond the final whistle, as it leaves a positive imprint on the host nation's infrastructure, economy, and netball development. The event is expected to inspire a new generation of netball players in South Africa, nurturing talent and promoting the growth of the sport at all levels.
          
       <br/> <br/>   As the countdown to the Netball World Cup in South Africa begins, the anticipation and excitement continue to grow. The tournament represents an opportunity to celebrate netball's global reach and the power of sport to unite and inspire. With South Africa as the proud host nation, the event is set to be a spectacle of talent, teamwork, and triumphs. The Netball World Cup promises to leave a legacy of empowerment, inspiration, and memories that will resonate with netball enthusiasts worldwide for years to come. Let the games begin!</div>,
          read: "READ MORE..."
        },
   
  ]

  let tspots = inspots.sort((a , b) => new Date(...a.date.split('/').reverse() )- new Date(...b.date.split('/').reverse()));
  let spots = tspots.reverse()
  return (
    <div>
      <div className='relative  bg-white  font-yabari'>
       
         <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>LOCAL STORIES</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
          
         
          <div className=" absolute lg:top-16  min-[100px]:left-10 min-[100px]:m-auto lg:right-0 md:top-12 min-[100px]:top-16  flex items-center  min-[100px]:justify-center lg:justify-end">
        <input className='caret-white  text-[12px ] text-black font-basc p-1 w-64 h-8 bg-white  placeholder-green-800 border-green-600 border-solid border-2 z=20' id="searchInput" type="text" placeholder="Search here..." onChange={(event) => {
            setSearchTerm(event.target.value);
          }} />
        </div>
          <div className= {openGal ? "block " : "hidden "}>
      <div  id="container" onClick={closeGal} className=' fixed bg-white/20 h-screen top-0 left-0 w-screen z-40 backdrop-blur-md  '>
      <div className='absolute right-0 left-0 top-0 bottom-0 m-auto flex flex-col  items-center font-basc pb-32 bg-white lg:w-[90%] min-[100px]:w-96  '>
        <div   onClick={() =>{  setOpenGal(!openGal)}}> <AiOutlineClose size={25} className='text-black cursor-pointer  bg-green-600 shadow-md absolute top-3 right-3' /> </div>
            <img className='lg:w-[90%] min-[100px]:w-96 shadow-lg' src={spots[ide].image}/> 
          
           
           <div className=' flex flex-col justify-center lg:w-[90%] min-[100px]:w-96 p-4 bg-white'>
            <span className='text-green-600 text-[12px] font-yabari my-2 '>{spots[ide].date}</span> 
            <span className='text-black text-sm font-yabari my-2 uppercase'>{spots[ide].title}</span> 
            <span className='text-black text-sm font-thin font-basc my-2'>{ spots[ide].text}</span> 
           </div>
       </div>
      </div>
      </div>
        <div className=' lg:px-[105px] lg:pt-40  md:px-28  z-10  md:pt-24   min-[100px]:p-8 min-[100px]:pt-32 grid  lg:gap-2 md:gap-8  min-[100px]:gap-8 lg:grid-cols-3 md:grid-cols-1'>
        {
            spots
              .filter((spot) => {
                if(searchTerm == ""){
                  return spot;
                }else if(spot.title.toLowerCase().includes(searchTerm.toLowerCase())){
                  return spot;
                }
              })
              .map((spot) =>(
                <div >
                 
                 <div className='relative font-basc'>
                  <img className='shadow-lg' src={ spot.image}/> <br/>
                
                 </div>
                 <div data-aos="fade-up" >
                  <span data-aos="fade-up" className='text-green-600 text-[12px] '>{spot.date}</span> <br/> <br/>
                  <span data-aos="fade-up" className='text-black text-sm uppercase'>{spot.title}</span> <br/>
                  <span data-aos="fade-up" className='text-black text-sm font-thin font-basc'>{spot.preview}
                  <br/>
                  <span data-aos="fade-up" id={spot.id} onClick={handleClose} className='text-center cursor-pointer  hover:text-green-600'>{spot.read}</span>
                  </span> 
                 
                 </div>
               
               </div>
               ))}
         
         </div>
         </div> 
      
         <div>
        
         </div>
    </div>
  )
}

export default LocalStories
