import React from 'react'
import img1 from  "../images/artin1.png"
import img2 from  "../images/artin2.png"
import img3 from  "../images/artin2.png"
import img4 from  "../images/artin3.png"
import img5 from  "../images/artin5.png"
import img6 from  "../images/artin6.png"
import { useState, useEffect } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import AOS from 'aos'
import 'aos/dist/aos.css'


const InterStories = () => {

  useEffect(()=>{
    AOS.init({duration: 1000})
 }, [])

  const [openGal, setOpenGal] = useState(false);
  const [ide , setIde] = useState(0)
  const [searchTerm , setSearchTerm] = useState(0)

  const closeGal = (e) => {
    if (e.target.id === "container"){
    setOpenGal(!openGal)}
    
 };

 const handleClose = (e, ) =>{
  const insp = e.target.id
  const inspo = insp-1
  setIde(inspo)
  setOpenGal(!openGal)
  console.log(ide)
}

 

  const inspots = [
    {
      id: 6,
      image : img1,
      date : '25/07/2023',
       title :   "South Africa: Netball World Cup - an Untold Black Sport History of South Africa", 
      preview: "Netball had become established in South Africa by 1917, much later than other sports that originated in the 1800s. South Africa, however, had a visible presence in the early years of codifying netball for international competition.",
      link:"https://allafrica.com/stories/202307260010.html ",
              read: "READ MORE..."
    },
    {
       id: 5,
        image : img2,
        date : '26/07/2023',
         title :   "Netball World Cup 2023: When does it start, how to watch and who are the favourites?", 
        preview: "Sixteen teams will take to court in Cape Town on Friday, 28 July - but only one will be crowned winner of the netball World Cup 10 days later. Here is everything you need to know about the tournament.",
        link:"https://www.bbc.com/sport/netball/66289256 ",
                read: "READ MORE..."
      },
      {
        id :4,
        image : img3,
        date : '26/07/2023',
         title :   "Netball: Can Silver Ferns defend World Cup for first time?", 
        preview: "Over the next two weeks , the Silver Ferns will attempt to do something they have never done before- win back to back Netball Worldcups" ,
        link: "https://www.newshub.co.nz/home/sport/2023/07/netball-can-silver-ferns-defend-the-world-cup-for-the-first-time.html ",
        read: "READ MORE..."
      },
      {
        id: 3,
        image : img4,
        date : '26/07/2023',
         title :   "Phumza Maweni says Proteas are pumped and eyeing a podium finish at the Netball World Cup", 
        preview: " When it comes to an experienced player you don’t really get better than Proteas stalwart Phumza Maweni. Maweni will be playing in her third Netball World Cup and for this reason will be relied on as South Africa look to go all the way and bring home the trophy.",
        link: "https://www.iol.co.za/sport/netball/phumza-maweni-says-proteas-are-pumped-and-eyeing-a-podium-finish-at-the-netball-world-cup-79168f75-0206-4b11-8e73-80a77b334566 ",
                read: "READ MORE"
      },
      {
         id: 2,
          image : img5,
          date : '26/07/2023',
           title :   "Netball World Cup: An untold black sport history of South Africa", 
          preview: "Netball had become established in South Africa by 1917, much later than other sports that originated in the 1800s. South Africa, however, had a visible presence in the early years of codifying netball for international competition. ",
         link: "https://www.msn.com/en-za/news/other/netball-world-cup-an-untold-black-sport-history-of-south-africa/ar-AA1emXWe",
                  read: "READ MORE..."
        },
        {
          id :1,
          image : img6,
          date : '26/07/2023',
           title :   "Netball World Cup kicks off with FREE concert at the fan park, 4pm TODAY!", 
          preview: "frica Melane speaks to Alderman JP Smith (Mayoral Committee Member at the City of Cape Town) about the free concert happening in the fan park today at 4 pm in the lead-up to the Netball World Cup which kicks off on Friday, 28 July. Yes, this is also why roads are closed in the city centre. " ,
          link: "https://www.msn.com/en-za/news/other/netball-world-cup-kicks-off-with-free-concert-at-the-fan-park-4pm-today/ar-AA1emOXB ",
          read: "READ MORE..."
        },
   
  ]

  let tspots = inspots.sort((a , b) => new Date(...a.date.split('/').reverse() )- new Date(...b.date.split('/').reverse()));
  let spots = tspots.reverse()
  return (
    <div>
      <div className='relative  bg-white   font-yabari'>
       
         <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>INTERNATIONAL NEWS</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
          
          
            
          <div className=" absolute lg:top-16  min-[100px]:left-10 min-[100px]:m-auto lg:right-0 md:top-12 min-[100px]:top-16  flex items-center  min-[100px]:justify-center lg:justify-end">
        <input className='text-[12px ] text-black font-basc p-1 w-64 h-8 bg-white  placeholder-green-800 border-green-600 border-solid border-2 z=20' id="searchInput" type="text" placeholder="Search here..." onChange={(event) => {
            setSearchTerm(event.target.value);
          }} />
        </div>
         
        <div className=' lg:px-[105px] lg:pt-40  md:px-28  z-10  md:pt-24   min-[100px]:p-8 min-[100px]:pt-32 grid  lg:gap-2 md:gap-8  min-[100px]:gap-8 lg:grid-cols-3 md:grid-cols-1'>
        {
            spots
              .filter((spot) => {
                if(searchTerm == ""){
                  return spot;
                }else if(spot.title.toLowerCase().includes(searchTerm.toLowerCase())){
                  return spot;
                }
              })
              .map((spot) =>(
                <div >
                 
                 <div className='relative font-basc'>
                  <img  className='shadow-lg' src={ spot.image}/> <br/>
                
                 </div>
                 <div data-aos="fade-up" >
                  <span data-aos="fade-up" className='text-green-600 text-[12px] '>{spot.date}</span> <br/> <br/>
                  <span data-aos="fade-up" className='text-black text-sm uppercase'>{spot.title}</span> <br/>
                  <span data-aos="fade-up" className='text-black text-sm font-thin font-basc'>{spot.preview}
                  <br/>
                  <a href={spot.link} data-aos="fade-up" id={spot.id} className='text-center cursor-pointer  hover:text-green-600'>{spot.read}</a>
                  </span> 
                 
                 </div>
               
               </div>
               ))}
         
         </div>
         </div> 
      
         <div>
        
         </div>
    </div>
  )
}

export default InterStories
