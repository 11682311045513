import React from 'react'

const Terms = ({ openNav, setOpenNav}) => {
  return (
    <div  className={openNav ? "open" : "close"}>
        <div className=' h-full  w-full m-auto  relative group font-yabari  bg-white text-black overflow-hidden  '>
    <div className='  lg:p-[105px] min-[100px]:p-8 lg:pt-32 min-[100px]:mt-32 font-basc'>
     <div className='3xl font-bold'>TERMS OF USE POLICY FOR NIGERIAN NETBALL FEDERATION (NNF) WEBSITE</div>

<br/> <br/> Welcome to the Nigerian Netball Federation (NNF) website. By accessing and using this website, you agree to comply with and be bound by the following Terms of Use. If you do not agree with these terms, please do not use this website.

<br/> <br/> <p className='font-bold'>1.Intellectual Property:</p>
<br/> <br/> All content, materials, and information on this website, including but not limited to text, graphics, logos, images, audio clips, video clips, and software, are the property of the Nigerian Netball Federation or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, modify, or use any content from this website without the express written consent of the NNF.

<br/> <br/> <p className='font-bold'>2.Website Use:</p>
<br/> <br/> You may use this website for personal, non-commercial purposes in accordance with these Terms of Use. You agree not to use this website for any unlawful or prohibited purpose. Any unauthorized use of this website may result in termination of your access.

<br/> <br/> <p className='font-bold'>3.User Conduct:</p>
<br/> <br/> While using this website, you agree not to:
<br/> <br/> Post, transmit, or distribute any content that is unlawful, harmful, defamatory, obscene, harassing, or otherwise objectionable.
<br/> <br/> Attempt to gain unauthorized access to any part of the website, server, or network connected to the website.
<br/> <br/> Interfere with or disrupt the operation of the website or its servers.
<br/> <br/> Impersonate any person or entity, or falsely state or misrepresent your affiliation with any person or entity.
<br/> <br/> Engage in any activity that could damage, disable, or impair the website or its functionality.

<br/> <br/> <p className='font-bold'>4.Third-Party Links:</p>
<br/> <br/> This website may contain links to third-party websites. These links are provided for your convenience only. The Nigerian Netball Federation does not endorse or control the content, products, or services offered by these third-party websites. Your use of third-party websites is subject to their respective terms of use and Terms policies.

<br/> <br/> <p className='font-bold'>5.Disclaimer of Liability:</p>
<br/> <br/> The Nigerian Netball Federation strives to provide accurate and up-to-date information on this website. However, we do not guarantee the accuracy, completeness, or reliability of any content on the website. The NNF disclaims any liability for errors or omissions in the content and shall not be held responsible for any damages or losses arising from the use of this website.

<br/> <br/> <p className='font-bold'>6.Disclaimer of Endorsement:</p>
<br/> <br/> Any references or links to products, services, or organizations on this website do not constitute endorsement or recommendation by the Nigerian Netball Federation. We are not responsible for the quality, safety, or legality of any third-party products or services.

<br/> <br/> <p className='font-bold'>7.Modification of Terms:</p>
<br/> <br/> The Nigerian Netball Federation reserves the right to modify or update these Terms of Use at any time without prior notice. Your continued use of the website after any changes to the terms will signify your acceptance of the updated terms.

<br/> <br/> <p className='font-bold'>8.Governing Law:</p>
<br/> <br/> These Terms of Use shall be governed by and construed in accordance with the laws of Nigeria. Any disputes arising from or related to the use of this website shall be subject to the exclusive jurisdiction of the courts of Nigeria.

<br/> <br/> <p className='font-bold'>9.Contact Information:</p>
<br/> <br/> If you have any questions or concerns about these Terms of Use, please contact us at info@netballngr.org .

<br/> <br/> By using the Nigerian Netball Federation website, you acknowledge that you have read, understood, and agreed to these Terms of Use. Thank you for visiting our website and supporting the growth of netball in Nigeria!
    </div></div></div>
  )
}

export default Terms
