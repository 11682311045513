
import React, { useEffect, useState } from 'react';

import '../index.css';
import img1 from "../images/mrhenry.jpg"
import img2 from "../images/mrsrahinatu.png"
import img3 from "../images/mrsesther.jpg"
import {RxCross1} from 'react-icons/rx'
import { BsArrowDownRight } from 'react-icons/bs';
import AOS from 'aos'
import 'aos/dist/aos.css'


const Exec = () => {

    useEffect(()=>{
        AOS.init({duration: 500})
     }, [])
     
     const [open, setOpen] = React.useState(null);
     const toggle = id => setOpen(open === id ? undefined : id)

    const execs = [
        { id : 1,
          exec : img1,
          name :   "Dr. Henry O. Nzekwu", 
          position : "President",
          info:  <p >Dr. Henry Nzekwu is an experienced thought leader, public speaker, and trusted advisor to organizations in both public and private sectors. He is the founder and Executive Vice Chairman of Messagewise Limited, a leading Strategy and Communications Consultancy firm in Nigeria. Dr. Nzekwu has over two decades of experience in public, media, strategic, and marketing communication competencies, and he has assisted several government institutions and private sector organizations to design and deliver world-class events and campaigns across the globe. He is also an expert in perception molding, stakeholder engagement, behavioral change communication, and sports management. Dr. Nzekwu has honed his expertise in sports marketing and administration, making him a foremost sports consultant in Nigeria. He is a member of various institutes and sits on the board of successful corporations.
          <br/>Dr. Henry Nzekwu's Sports Profile reveals his extensive experience in sports medicine and administration. He has served as the team doctor for the Nigerian Basketball Teams, the National Football Team, the Athletics Federation, and the Nigerian Amateur Boxing Association. He has also worked as the team doctor at various international sports championships, including the World Basketball Tourney in Edmonton, Canada, and the World Cup qualifier in Cairo. Apart from his work as a sports medicine practitioner, Dr. Nzekwu has also honed his expertise in sports marketing and administration, earning him critical recognition as a foremost sports consultant in Nigeria.
          <br/> As the President of the Nigerian Netball Federation, his experience in sports management and marketing, as well as his expertise in stakeholder engagement and behavioral change communication, have been valuable assets to the organization.</p>
        },
        { id :2 ,
          exec : img2,
          name :   "Air Commodore Rahinatu Garba, mni (rtd.)", 
          position : "Vice President, Technicals, Development and Performance",    
           info: <p>Air Commodore Rahinatu Garba, mni, (rtd) is an accomplished military officer with an impressive track record of appointments and achievements in physical training, sports development, and international peacekeeping missions. She was born on October 26, 1969, in Bambam, Gombe State, and hails from Balanga Local Government Area of Gombe State.
           Throughout her career, Garba held various appointments, including Chief Instructor at the Nigeria Air Force Physical Training School Kaduna between 1988-1999, Commanding Officer Air Force Physical Training School Kaduna from 1999 to 2004, Commanding Officer Air Force Physical Training School Kaduna from 2008 to 2010, and more. She also served with the United Nations (UN) as a team leader of Demobilization, Destruction, Rehabilitation, and Reintegration Team (DDRR) in the United Nations Mission in Burundi and Team leader Joint Monitoring Team (JMT) United Nations Mission in NEPAL in 2006 and 2007, respectively. Garba was also the Chairman Gender Sports, West African Military Sports Council (WALO) from 2012 to 2018, and the Chairman Organizing Committee Team HQ NAF Combat Games, First and Second Editions 2016 and 2017, respectively. She also served as a Board member and Deputy Chairman Caretaker Committee Shooting Sports Federation (NSSF) from 2018 to 2021, among other appointments.
           Garba is decorated with several awards and commendations, including the United Nations Medals (ONUB, UNMIN), Leadership Award by Waja Youth Forum (Federal Polytechnic, Bauchi), Overall Best in Drill certificate (Depot Nigerian Army), Best Shot (Depot Nigerian Army) certificate, Letter of Commendation from the Presidency for outstanding performance and commitment as a personal Trainer and corrective exercise specialist in 2020, and Award of Excellence in recognition of her contribution to the Development of the Gender Policy of the Armed Forces of Nigeria, Federal Ministry of Women Affairs, 2021. She is also a recipient of the Organisation of Military Sport in Africa (OSMA) Order of Merit Award as Gender Officer Ambassador in recognition of her commitment to the Development of OSMA activities in 2022.
           Garba is a member of various professional associations, including the Nigeria Association of Physical Education and Research-Sports and Dance (NAPHER-SD), Association of Former Female Athlete of Nigeria, and Alumni Association of National Institute, (AANI) SEC 43. She is also the Vice President of the Association of Former Female Athletes of Nigeria (AFFAN) and founder of Rahin
           As Vice President (Technical, Development and Performance), she brings a wealth of experience in physical training and sports development to the role.</p>
        },
        { id :3 ,
          exec : img3,
          name :   "Dr. Mrs. Esther Aluko", 
          position : "General Secretary",
          info: <p>Dr. Mrs. Esther Oluwatoyin Aluko is a highly accomplished national athlete, coach, and sport administrator with over 45 years of experience. She holds a first degree in Human Kinetics, a Master Degree in Sport Administration and Organisation, a Doctoral Degree in Sport Administration and Organisation, and a Diploma in Olympic Studies, among other coaching and administrative courses. Esther has represented Nigeria as a Handball player and won medals for the country. 
          She has served as the first female national handball coach for fourteen years and held various leadership roles in sport administration, including Secretary General to Eight National Sports Federations at various times. Esther is widely traveled and has participated in various international competitions, including the African Championships, All Africa Games, Africa Youth Championships, World Championships, Commonwealth Games, and Olympic Games.
          Esther is currently a board member of the Handball Federation of Nigeria, a member of the African Handball Confederation Coaching and Methods Commission, a member of the Association of National Olympic Committees of Africa Sustainability Commission, a member of the Africa Sports Management Association Nigeria Chapter, and the President of the Association of Former Female Athletes of Nigeria.
          Esther is known for her strategic thinking and ability to inspire and motivate athletes and staff. She is also an advocate for athletes' development and well-being and has mentored and trained numerous young athletes to success.
          With her wealth of experience, Esther has been an excellent General Secretary for the Nigerian Netball Federation.</p>
        },
      ]
  return (
    <div>
       <div className=' bg-green-950'>
          <div className=' ' >
         

       <div className='relative  font-yabari'>
         <div className=' absolute lg:top-10 md:top-8 min-[100px]:top-5 flex items-center justify-between w-full'  >
          <span className='lg:p-2 lg:px-12 lg:mr-2 bg-green-600 md:p-2 md:px-12 md:mr-1  min-[100px]:p-1  min-[100px]:px-4'></span>
          <span className=' text-green-600 lg:text-sm  md:text-xs min-[100px]:text-md m-2 '>THE TEAM</span>
          <span className=' bg-green-600  md:p-2 flex grow min-[100px]:p-1'></span></div>
          </div>
          <div className=' lg:px-[105px]  min-[100px]:p-8 lg:pt-28 lg:pb-[64px] md:pt-24 grid min-[100px]:py-20  lg:gap-3 md:gap-8  min-[100px]:gap-8  lg:grid-cols-3 md:grid-cols-1'  data-aos="fade-up">
         {execs.map((exec) => (
          <div className=' '>
           <div  onClick={() => toggle(exec.id)} className='relative flex items-center justify between flex-col'>
           <div> <img className='w-[900px]' src={ exec.exec}/> </div>
           <div className='  flex lg:w-full md:w-[700px]  min-[100px]:w-full md:p-4  min-[100px]:p-2 items-center justify-between bg-green-600   text-green-950 text-xs'>
           <span  className='lg:text-xs  min-[100px]:text-xs uppercase'  >{exec.name} <br/> <span className='text-sm mt-5 font-basc'>{exec.position}</span></span>
           
          
           </div>

            <div className= "border-solid overflow-hidden font-thin"  >
            <div className= {open === exec.id  ? "exec_show" : "exec_noshow"}><p className='bg-green-600 font-semibold font-basc text-green-950 p-3'>{exec.info}</p></div>
           </div>
          
             </div>
         
         </div>
         ))}
         </div>
    </div>
    </div>
    </div>
  )
}

export default Exec
